<template>
  <section>
    <h2>Frequently Asked Questions</h2>
    <BookViewGoogleFaq />
    <div class="content-container">
      <div class="faq" v-for="faq in faqs" :key="faq.id">
        <h3 @click="() => handleTitleClick(faq.id)">{{ faq.title }}</h3>
        <TransitionedParagraph :isOpen="faq.isOpened" :text="faq.paragraph" />
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
import breakpointStore from '@/store/breakpoints'
import faqApi from '@/services/faqApi'
import TransitionedParagraph from '@/components/atoms/TransitionedParagraph'
import BookViewGoogleFaq from './BookViewGoogleFaq'

export default {
  name: 'BookViewFaq',
  components: {
    TransitionedParagraph,
    BookViewGoogleFaq
  },

  async created() {
    const fetchedFaqs = await faqApi.fetchFaq()
    this.faqs = fetchedFaqs.map(faq => ({
      ...faq,
      isOpened: false
    }))
  },

  setup() {
    const faqs = ref([])
    const handleTitleClick = id => {
      faqs.value = faqs.value.map(faq => ({
        ...faq,
        isOpened: faq.id === id ? !faq.isOpened : false
      }))
    }

    return {
      faqs,
      handleTitleClick,
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}
h2 {
  margin-bottom: 2rem;
}
.faq {
  margin-bottom: 1rem;
}
.faq h3 {
  cursor: pointer;
  transition: all var(--transition-speed);
}
.faq h3:hover {
  color: var(--color-primary-darker);
}

.faq p {
  max-height: 0;
  transition: max-height 0.7s ease-out;
  overflow: hidden;
  text-align: justify;
}
.faq p.opened {
  max-height: 200px;
  transition: max-height 0.7s ease-in;
}
</style>

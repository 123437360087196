import utils from '@/services/utils/galleryApiUtils'
import portraits from '@/assets/images/portfolio/portraits/portraits'
import couples from '@/assets/images/portfolio/couples/couples'
import locationsApi from './locationsApi'

const resolveLocationImages = () =>
  locationsApi.fetchLocations().reduce(
    (locations, location) => ({
      ...locations,
      [location.id]: utils.shufflePhotos(
        [...portraits, ...couples].filter(
          photo => photo.location === location.id
        )
      )
    }),
    {}
  )

const allImages = {
  home: utils.shufflePhotos([...portraits, ...couples]),
  portraits: utils.shufflePhotos([...portraits]),
  couples: utils.shufflePhotos([...couples]),
  ...resolveLocationImages()
}

const fetchImages = key => {
  if (!allImages[key]) return

  const aggregations = {
    2: utils.aggregateToTwoColumns([...allImages[key]]),
    3: utils.aggregateToThreeColumns([...allImages[key]]),
    4: utils.aggregateToFourColumns([...allImages[key]]),
    5: utils.aggregateToFiveColumns([...allImages[key]])
  }

  for (const key in aggregations) {
    aggregations[key] = {
      images: aggregations[key],
      interestingImagesIds: utils.getInterestingImagesIds(aggregations[key])
    }
  }

  return aggregations
}

export default {
  fetchImages,
  allImages
}

import arrowLeft from './icons/arrow-left.svg'
import arrowRight from './icons/arrow-right.svg'
import burger from './icons/burger.svg'
import cross from './icons/cross.svg'
import { default as loading, default as logo } from './logo-loading.png'
import logoMenuHover from './logo-menu-hover.png'
import logoMenu from './logo-menu.png'

import socialInstagram from './icons/social-instagram.svg'
import socialMail from './icons/social-mail.svg'
import socialPinterest from './icons/social-pinterest.svg'
import socialWhatsapp from './icons/social-whatsapp.svg'

import portfolio_couples_001 from './portfolio/_landing/portfolio-couples-001.jpg'
import portfolio_portraits_001 from './portfolio/_landing/portfolio-portraits-001.jpg'

import service_couple from './services/service-couple.jpg'
import service_headshot from './services/service-headshot.jpg'
import service_solo from './services/service-solo.jpg'

import location_amsterdam from './locations/location-amsterdam.jpg'
import location_gems from './locations/location-gems.jpg'
import location_knsm from './locations/location-knsm.jpg'
import location_ndsm from './locations/location-ndsm.jpg'
import location_oosterpark from './locations/location-oosterpark.jpg'
import location_world from './locations/location-world.jpg'

import clothes_001 from './clothes/clothes-001.jpg'
import clothes_002 from './clothes/clothes-002.jpg'
import clothes_003 from './clothes/clothes-003.jpg'

import storiesRightOutfitSmall from './stories/right-outfit-small.jpg'
import storiesRightOutfit from './stories/right-outfit.jpg'
import storiesSelfLoveSmall from './stories/self-love-small.jpg'
import storiesSelfLove from './stories/self-love.jpg'
import storiesAdelaLivioSmall from './stories/adela-livio-small.jpg'
import storiesAdelaLivio from './stories/adela-livio.jpg'

export default {
  logo,
  logoMenu,
  logoMenuHover,
  loading,
  cross,
  burger,
  arrowLeft,
  arrowRight,

  socialInstagram,
  socialPinterest,
  socialMail,
  socialWhatsapp,

  portfolio_portraits_001,
  portfolio_couples_001,

  service_solo,
  service_couple,
  service_headshot,

  location_amsterdam,
  location_gems,
  location_knsm,
  location_ndsm,
  location_oosterpark,
  location_world,

  clothes_001,
  clothes_002,
  clothes_003,

  'stories_right-outfit': storiesRightOutfit,
  'stories_right-outfit-small': storiesRightOutfitSmall,
  'stories_self-love': storiesSelfLove,
  'stories_self-love-small': storiesSelfLoveSmall,
  'stories_adela-livio': storiesAdelaLivio,
  'stories_adela-livio-small': storiesAdelaLivioSmall
}

<template>
  <div
    class="lazy-img-container"
    :class="{ __loading: isLoading, __cover: isCover, __gallery: isGallery }"
    :style="{
      'min-width':
        !useDefaultSize && `${size.x}${units === 'viewport' ? 'vw' : units}`,
      'max-width':
        !useDefaultSize && `${size.x}${units === 'viewport' ? 'vw' : units}`,
      'min-height':
        !useDefaultSize &&
        !isCover &&
        `${size.y}${units === 'viewport' ? 'vh' : units}`,
      'max-height':
        !useDefaultSize &&
        !isCover &&
        `${size.y}${units === 'viewport' ? 'vh' : units}`
    }"
  >
    <img v-show="isLoading" :src="loadingImage" alt="Loading" />
    <img v-show="!isLoading" :src="image" :alt="alt" @load="onLoad" />
  </div>
</template>

<script>
import images from '@/assets/images/images'
import { ref } from '@vue/reactivity'

export default {
  name: 'LazyImage',
  props: {
    image: { type: String, required: true },
    alt: { type: String, required: true },
    useDefaultSize: { type: Boolean, default: false },
    size: { tpe: Object, default: { x: 450, y: 350 } },
    units: { type: String, default: 'px' },
    isCover: { type: Boolean, default: false },
    isGallery: { type: Boolean, default: false }
  },
  emits: ['onLoad'],

  setup(props, { emit }) {
    const isLoading = ref(true)
    const onLoad = () => {
      isLoading.value = false
      emit('onLoad')
    }

    // Backup plan, if @load does not work
    setTimeout(() => {
      isLoading.value = false
      emit('onLoad')
    }, 2000)

    return {
      isLoading,
      onLoad,
      loadingImage: images.loading
    }
  }
}
</script>

<style scoped>
.lazy-img-container {
  display: flex;
  align-items: center;
  justify-content: center;

  background-size: contain !important;
  background-repeat: no-repeat !important;
  border: 2px solid transparent;

  transition: opacity 0.3s ease-in-out;
  overflow: hidden;
}
.lazy-img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.lazy-img-container.__cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lazy-img-container.__loading {
  border: 2px solid var(--color-primary-darkest);
  opacity: 0.3;
}
.lazy-img-container.__loading.__gallery {
  padding: 10px;
  border-color: transparent;
}
.lazy-img-container.__loading img {
  width: 150px;
  height: 150px;
  animation: pulse 1.3s infinite;
}
.lazy-img-container.__loading.__gallery img {
  width: 50px;
  height: 50px;
}
.lazy-img-container.__loading.__cover img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.lazy-img-container.__cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>

<template>
  <section>
    <h2>Testimonials</h2>

    <div class="testimonials-container">
      <div
        v-for="(testimonial, index) in testimonials"
        :key="testimonial.name"
        class="testimonial"
        :class="index % 2 === 0 ? 'left' : 'right'"
      >
        <h3>{{ testimonial.name }}</h3>
        <div class="message">{{ testimonial.message }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import testimonialsApi from '@/services/testimonialsApi'
import { ref } from 'vue'

export default {
  name: 'BookViewTestimonials',

  created() {
    this.testimonials = testimonialsApi.fetchTestimonials()
  },

  setup() {
    const testimonials = ref([])

    return {
      testimonials
    }
  }
}
</script>

<style scoped>
section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}
.testimonials-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}
.testimonial.right {
  margin-left: auto;
  text-align: right;
}
.testimonial {
  max-width: 85%;
  margin: 0.7rem;
}
.testimonial h3 {
  margin-bottom: 0.2rem;
}
.testimonial .message {
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  border-radius: 1em;
  line-height: 1.7;
  padding: 0.5em 1em;
  text-align: left;
  background-color: var(--color-primary-darkest);
  color: var(--color-primary);
}
.testimonial.right .message {
  border-bottom-right-radius: 0.1em;
}
.testimonial.left .message {
  border-bottom-left-radius: 0.1em;
}
</style>

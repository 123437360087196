<template>
  <div
    class="input-container"
    :class="{
      'is-error': vuelidate && vuelidate.$errors.length,
      'bottom-margin': !vuelidate
    }"
  >
    <label :for="label">{{ label }}</label>
    <span v-if="subLabel">{{ subLabel }}</span>

    <template v-if="element === 'textarea'">
      <textarea
        :id="label"
        :value="modelValue"
        :placeholder="placeholder"
        @input="handleInput"
        rows="5"
      ></textarea>
    </template>
    <template v-else>
      <input
        :id="label"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        @input="handleInput"
      />
    </template>

    <div v-if="vuelidate" class="error-container">
      <span v-if="vuelidate.$errors[0]">
        {{ vuelidate.$errors[0].$message }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: [
    'modelValue',
    'label',
    'subLabel',
    'placeholder',
    'vuelidate',
    'element',
    'type'
  ],
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const handleInput = event => {
      if (props.vuelidate) {
        props.vuelidate.$touch()
      }
      emit('update:modelValue', event.target.value)
    }

    return {
      handleInput
    }
  }
}
</script>

<style scoped>
.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}
.input-container.bottom-margin {
  margin-bottom: 1.2rem;
}
input,
textarea {
  width: calc(100% - 2rem - 4px);
  padding: 0.8rem 1rem;
  margin: 0.5rem 0;
  display: inline-block;
  border: 2px solid var(--color-primary);
  border-radius: 4px;
  background-color: var(--color-primary-darkest);
  color: var(--color-primary);
  box-shadow: 3px 7px 10px 0px rgba(0, 0, 0, 0.05);
  transition: border var(--transition-speed);
  outline: none;
  font-size: 0.9rem;
  font-family: itc-avant-garde-gothic-pro-300, sans-serif;
  letter-spacing: 0.4px;
}
input:hover,
input:focus,
textarea:hover,
textarea:focus {
  border: 2px solid var(--color-primary-dark);
}
label {
  font-size: 0.9rem;
}
label ~ span {
  margin-top: 5px;
  font-size: 0.75rem;
  color: var(--color-primary);
  text-align: left;
}

.input-container.is-error input,
.input-container.is-error textarea {
  background-color: var(--color-primary-darkest);
  border: 2px solid var(--color-error);
}
.error-container {
  height: 30px;
  font-family: museo-slab, serif;
  font-size: 0.85rem;
  color: var(--color-error);
}

@media (max-width: 600px) {
  input,
  textarea {
    width: calc(100% - 1.6rem - 4px);
    padding: 0.6rem 0.8rem;
    margin: 0.25rem 0 0.15rem 0;
  }
  .error-container {
    height: 20px;
    font-size: 0.6rem;
  }
}
</style>

import images from '@/assets/images/images'

const fetchServices = async () => {
  return [
    {
      id: 'couple',
      title: 'Couple Lovestory Photoshoot',
      price: 580,
      text:
        'Enjoy a&nbspfun and dreamy lovestory photoshoot. I will capture the memories for you and your loved one. Get excited with 30 edited photos in your online gallery, where you can even have a few solo shots.',
      image: images.service_couple
    },
    {
      id: 'solo',
      title: 'Creative Solo Photoshoot',
      price: 460,
      text:
        "Stop dreaming about glamorous photos of you, let's make them happen.  Are you in Amsterdam on vacation and want to capture these memories? You can look forward to 25 edited photos in the online gallery.",
      image: images.service_solo
    },
    {
      id: 'headshot',
      title: 'Headshot Photos for LinkedIn or CV',
      price: 180,
      text:
        'Need a new profile photo on your LinkedIn or CV? Fast and pleasant photoshoot from which you will get professional 5 photos in online gallery, ready for your business social networks.',
      image: images.service_headshot
    }
  ]
}

const servicesMap = {
  couple: 'Couple / Lovestory',
  solo: 'Solo / Creative',
  headshot: 'Headshot / personal image for LinkedIn or CV',
  event: 'Events, products and other - please specify'
}

export default {
  fetchServices,
  servicesMap
}

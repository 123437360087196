<template>
  <footer>
    <div class="footer-container">
      <div
        v-if="breakpoint.value !== 'XS' && breakpoint.value !== 'SM'"
        class="cta-container"
      >
        <FooterAction />
      </div>

      <div class="socials-container">
        <span v-if="breakpoint.value !== 'XS' && breakpoint.value !== 'SM'">
          Let’s be friends
        </span>
        <Socials isFooter="true" />
      </div>

      <div class="info-container">
        <span>
          <span v-if="breakpoint.value === 'XS' || breakpoint.value === 'SM'">
            <a href="/contact-me">Book a shoot</a>
            with
          </span>
          Petra
          <span v-if="breakpoint.value !== 'XS' && breakpoint.value !== 'SM'">
            Prochazkova
          </span>
        </span>
        <br />
        <span>Photographer based in Amsterdam</span>
        <br />
        <span>KVK Number 83242961</span>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterAction from '@/components/nav/FooterAction.vue'
import Socials from '@/components/nav/Socials.vue'
import breakpoints from '@/store/breakpoints'

export default {
  name: 'Footer',
  props: ['isHome'],
  components: {
    Socials,
    FooterAction
  },

  setup() {
    return {
      breakpoint: breakpoints.breakpoint
    }
  }
}
</script>

<style scoped>
footer {
  position: fixed;
  bottom: 0;
  background: var(--color-black);
  height: var(--footer-height);

  width: 100%;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  opacity: 0.8;
}
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1200px;
}
.footer-container div {
  margin: 0rem 1rem;
}
.socials-container,
.cta-container,
.info-container {
  width: 33%;
}
.socials-container {
  order: 1;
}
.cta-container {
  order: 2;
}
.info-container {
  order: 3;
}
.socials-container > span,
.info-container > span,
.cta-container > span {
  display: inline-block;
  margin-bottom: 0.3rem;
}
footer a {
  color: var(--color-primary);
  text-decoration: none;
  transition: var(--transition-speed);
}
footer a:hover {
  color: var(--color-primary-darker);
}
span {
  font-family: itc-avant-garde-gothic-pro-300, sans-serif;
  font-size: 0.9rem;
}

@media (max-width: 960px) {
  .socials-container span,
  .info-container span,
  .cta-container span {
    margin-bottom: 0rem;
    font-size: 0.8rem;
  }
}
@media (max-width: 600px) {
  footer {
    width: calc(100% - 3rem);
    padding: 0rem 1.5rem;
    font-size: 0.6rem;
  }
  .footer-container div {
    margin: 0.5rem;
  }
  .socials-container {
    width: 20%;
    margin: 0rem;
  }
  .info-container {
    width: 70%;
  }
  .socials-container span,
  .info-container span,
  .cta-container span {
    font-size: 0.7rem;
  }
}
</style>

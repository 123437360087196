import images from '@/assets/images/images'

const fetchLocations = () => {
  return [
    {
      id: 'ndsm',
      title: 'NDSM',
      image: images.location_ndsm
    },
    {
      id: 'oosterpark',
      title: 'Oosterpark',
      image: images.location_oosterpark
    },
    {
      id: 'amsterdam',
      title: 'Amsterdam city centre',
      image: images.location_amsterdam
    },
    {
      id: 'knsm',
      title: 'Java/KNSM Eiland',
      image: images.location_knsm
    },
    {
      id: 'gems',
      title: 'Hidden gems around Amsterdam',
      image: images.location_gems
    },
    {
      id: 'world',
      title: 'Your favourite location in the world!',
      image: images.location_world
    }
  ]
}

export default {
  fetchLocations
}

<template>
  <div class="nav-links-container" :class="{ 'is-vertical': isVertical }">
    <router-link v-if="$route.name !== 'links'" to="/" @click="handleClick">
      <h1 v-if="$route.name === 'home'">Home</h1>
      <span v-else>Home</span>
    </router-link>

    <router-link
      v-if="$route.name !== 'links'"
      to="/about"
      @click="handleClick"
    >
      <h1 v-if="$route.name === 'about'">About</h1>
      <span v-else>About</span>
    </router-link>

    <router-link
      v-if="$route.name !== 'links'"
      to="/services"
      @click="handleClick"
      class="no-margin"
    >
      <h1 v-if="$route.name === 'services'">Services</h1>
      <span v-else>Services</span>
    </router-link>

    <router-link
      class="img-link"
      v-if="!isVertical"
      to="/"
      @click="handleClick"
      @mouseover="isHovered = true"
      @mouseout="isHovered = false"
    >
      <img
        :src="isHovered ? logoImgHover : logoImg"
        alt="petrapro.art photography"
      />
    </router-link>

    <router-link
      v-if="$route.name !== 'links'"
      to="/stories"
      @click="handleClick"
    >
      <h1 v-if="$route.name === 'stories'">Stories</h1>
      <span v-else>Stories</span>
    </router-link>

    <router-link
      v-if="$route.name !== 'links'"
      to="/portfolio"
      @click="handleClick"
    >
      <h1 v-if="$route.name === 'portfolio'">Gallery</h1>
      <span v-else>Gallery</span>
    </router-link>

    <router-link
      v-if="$route.name !== 'links'"
      to="/contact-me"
      @click="handleClick"
      :class="['no-margin', !isVertical && 'text-left']"
    >
      <h1 v-if="$route.name === 'contact'">Contact</h1>
      <span v-else>Contact</span>
    </router-link>
  </div>
</template>

<script>
import images from '@/assets/images/images'
import { ref } from 'vue-demi'

export default {
  name: 'NavLinks',
  props: ['isVertical'],

  setup(props, { emit }) {
    const isHovered = ref(false)
    const handleClick = () => emit('linkClicked')

    return {
      logoImg: images.logoMenu,
      logoImgHover: images.logoMenuHover,
      isHovered,
      handleClick
    }
  }
}
</script>

<style scoped>
.nav-links-container {
  display: flex;
  align-items: center;
}
.is-vertical {
  flex-direction: column;
  padding-bottom: 2rem;
}

a {
  width: 95px;
  margin-right: 1.5rem;
  padding: 0.2rem;

  color: var(--color-primary);
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all var(--transition-speed);
  text-transform: uppercase;
}
a h1,
a span {
  font-size: 1.1rem;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-family: itc-avant-garde-gothic-pro-300, sans-serif;
}
a.no-margin {
  margin-right: 0;
}
a.text-left {
  text-align: left;
}
a:hover {
  color: var(--color-primary-dark);
}
a.router-link-exact-active {
  color: var(--color-primary-dark);
}

.is-vertical a {
  margin-right: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.img-link {
  width: 210px;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.img-link img {
  width: 210px;
}
</style>

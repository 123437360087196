<template>
  <main>
    <div class="content-container">
      <MemoriesHeading />
      <BookViewIntro />
      <BookViewLocations />
      <BookViewClothes />
      <BookViewTestimonials />
      <BookViewFaq />
    </div>

    <div
      class="actions-container"
      v-if="breakpoint.value === 'XS' || breakpoint.value === 'SM'"
    >
      <BaseButton
        class="primary"
        :onClick="() => $router.push({ name: 'contact' })"
      >
        Contact me
      </BaseButton>
    </div>
  </main>
</template>

<script>
import BaseButton from '@/components/atoms/BaseButton'
import BookViewClothes from '@/components/bookView/BookViewClothes.vue'
import BookViewFaq from '@/components/bookView/BookViewFaq.vue'
import BookViewIntro from '@/components/bookView/BookViewIntro.vue'
import BookViewLocations from '@/components/bookView/BookViewLocations.vue'
import BookViewTestimonials from '@/components/bookView/BookViewTestimonials.vue'
import MemoriesHeading from '@/components/MemoriesHeading'
import breakpointStore from '@/store/breakpoints'

export default {
  name: 'AboutView',
  components: {
    MemoriesHeading,
    BaseButton,
    BookViewIntro,
    BookViewLocations,
    BookViewClothes,
    BookViewTestimonials,
    BookViewFaq
  },

  setup() {
    return {
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
.content-container {
  align-items: center;
}
</style>

<template>
  <button class="button" @click="onClick">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    onClick: { type: Function, default: () => {}, required: true }
  }
}
</script>

<style>
.button {
  min-width: 200px;
  min-height: 50px;

  margin: 0.5rem;
  padding: 1rem 1.8rem;
  border-radius: 0.12rem;

  border: none;
  border-radius: 0.5rem;
  box-shadow: 3px 7px 10px 0px rgba(0, 0, 0, 0.15);

  color: var(--color-primary);
  font-size: 1.1rem;
  font-family: politica-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1px;

  cursor: pointer;
  text-transform: uppercase;

  transition: all var(--transition-speed);
}

.button.primary {
  color: var(--color-black);
  background-color: var(--color-primary);
}
.button.primary:hover {
  color: var(--color-black);
  background-color: var(--color-primary-darker);
}
.button.isLoading,
.button.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.button.mini {
  min-width: 100px;
  min-height: 35px;

  font-size: 0.75rem;
  padding: 0.2rem 1rem;
  margin: 0;
}

.button.links {
  width: 100%;
  padding: 0.6em 2rem;
  font-size: 0.9rem;
}

@media (max-width: 960px) {
  .button {
    min-width: 150px;
    padding: 0.8rem 1rem;
    font-size: 0.9rem;
  }
}
@media (max-width: 600px) {
  .button {
    padding: 0.8rem 1rem;
    font-size: 0.9rem;
  }
}
</style>

const aggregateToTwoColumns = images => {
  const column01 = []
  const column02 = []

  while (images.length > 0) {
    column01.push(images.shift())
    column02.push(images.shift())
  }
  return {
    1: column01.filter(Boolean),
    2: column02.filter(Boolean)
  }
}
const aggregateToThreeColumns = images => {
  const column01 = []
  const column02 = []
  const column03 = []

  while (images.length > 0) {
    column01.push(images.shift())
    column02.push(images.shift())
    column03.push(images.shift())
  }
  return {
    1: column01.filter(Boolean),
    2: column02.filter(Boolean),
    3: column03.filter(Boolean)
  }
}
const aggregateToFourColumns = images => {
  const column01 = []
  const column02 = []
  const column03 = []
  const column04 = []

  while (images.length > 0) {
    column01.push(images.shift())
    column02.push(images.shift())
    column03.push(images.shift())
    column04.push(images.shift())
  }
  return {
    1: column01.filter(Boolean),
    2: column02.filter(Boolean),
    3: column03.filter(Boolean),
    4: column04.filter(Boolean)
  }
}

const aggregateToFiveColumns = images => {
  const column01 = []
  const column02 = []
  const column03 = []
  const column04 = []
  const column05 = []

  while (images.length > 0) {
    column01.push(images.shift())
    column02.push(images.shift())
    column03.push(images.shift())
    column04.push(images.shift())
    column05.push(images.shift())
  }
  return {
    1: column01.filter(Boolean),
    2: column02.filter(Boolean),
    3: column03.filter(Boolean),
    4: column04.filter(Boolean),
    5: column05.filter(Boolean)
  }
}

const getInterestingImagesIds = aggregation => {
  return Object.values(aggregation).reduce((acc, cur) => {
    const firstImages = cur.slice(0, 5)
    acc = [...acc, ...firstImages.map(image => image.id)]
    return acc
  }, [])
}

const shufflePhotos = photos =>
  photos
    // 0. filter photos without || 0 priority
    .filter(photo => !!photo.priority && photo.priority > 0)
    // 1. groupBy priority
    .reduce((groupedByPriority, photo) => {
      if (!groupedByPriority[photo.priority]) {
        groupedByPriority[photo.priority] = [photo]
      } else {
        groupedByPriority[photo.priority].push(photo)
      }
      return groupedByPriority
    }, [])
    // 2. filter out empty priorities
    .filter(Boolean)
    // 3. shuffle every priority
    .map(photos => shuffleArray(photos))
    // 4. reverse so we have biggest priority in the beginning
    .reverse()
    // 5. made array flat again
    .reduce((photos, photosByPriority) => [...photos, ...photosByPriority], [])

const shuffleArray = array => {
  const randomizedArray = [...array]
  for (var i = randomizedArray.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = randomizedArray[i]
    randomizedArray[i] = randomizedArray[j]
    randomizedArray[j] = temp
  }
  return randomizedArray
}

const repeatImages = aggregations => {
  aggregations[2].images[1] = [
    ...aggregations[2].images[1],
    ...aggregations[2].images[1]
  ]
  aggregations[2].images[2] = [
    ...aggregations[2].images[2],
    ...aggregations[2].images[2]
  ]

  aggregations[3].images[1] = [
    ...aggregations[3].images[1],
    ...aggregations[3].images[1]
  ]
  aggregations[3].images[2] = [
    ...aggregations[3].images[2],
    ...aggregations[3].images[2]
  ]
  aggregations[3].images[3] = [
    ...aggregations[3].images[3],
    ...aggregations[3].images[3]
  ]

  aggregations[4].images[1] = [
    ...aggregations[4].images[1],
    ...aggregations[4].images[1]
  ]
  aggregations[4].images[2] = [
    ...aggregations[4].images[2],
    ...aggregations[4].images[2]
  ]
  aggregations[4].images[3] = [
    ...aggregations[4].images[3],
    ...aggregations[4].images[3]
  ]
  aggregations[4].images[4] = [
    ...aggregations[4].images[4],
    ...aggregations[4].images[4]
  ]

  aggregations[5].images[1] = [
    ...aggregations[5].images[1],
    ...aggregations[5].images[1]
  ]
  aggregations[5].images[2] = [
    ...aggregations[5].images[2],
    ...aggregations[5].images[2]
  ]
  aggregations[5].images[3] = [
    ...aggregations[5].images[3],
    ...aggregations[5].images[3]
  ]
  aggregations[5].images[4] = [
    ...aggregations[5].images[4],
    ...aggregations[5].images[4]
  ]
  aggregations[5].images[5] = [
    ...aggregations[5].images[5],
    ...aggregations[5].images[5]
  ]

  return aggregations
}

export default {
  aggregateToTwoColumns,
  aggregateToThreeColumns,
  aggregateToFourColumns,
  aggregateToFiveColumns,
  getInterestingImagesIds,
  shufflePhotos,
  repeatImages
}

<template>
  <div class="select-container">
    <label :for="label">{{ label }}</label>
    <VueNextSelect
      v-model="activeValue"
      :options="options"
      :close-on-select="true"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import VueNextSelect from 'vue-next-select'

export default {
  name: 'BaseSelect',
  props: ['modelValue', 'options', 'label'],
  emits: ['update:modelValue'],
  components: {
    VueNextSelect
  },

  setup(props, { emit }) {
    const activeValue = ref(props.modelValue)
    watch(activeValue, newValue => {
      emit('update:modelValue', newValue)
    })

    return {
      activeValue
    }
  }
}
</script>

<style scoped>
.select-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}
.vue-select {
  width: 100%;
  padding: 0.8rem 1rem;
  margin: 0.5rem 0 1rem 0;
  display: inline-block;
  border: 2px solid var(--color-primary-darker);
  border-radius: 4px;
  background-color: var(--color-primary-darkest);
  box-shadow: 3px 7px 10px 0px rgba(0, 0, 0, 0.05);
  transition: border var(--transition-speed);
  outline: none;
}
.vue-select:hover,
.vue-select:focus {
  border: 2px solid var(--color-primary);
}
label {
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .vue-select {
    padding: 0.6rem 0.8rem;
  }
}
</style>

<style>
.vue-dropdown {
  background-color: var(--color-primary-darkest);
  border: 2px solid var(--color-primary);
  left: -2px;
}
.vue-dropdown-item {
  font-family: itc-avant-garde-gothic-pro-300, sans-serif;
  letter-spacing: 0.4px;
  font-size: 0.9rem;
  text-align: left;
  padding: 0.3rem 1.1rem;
}
.vue-input {
  padding: 0;
  font-family: itc-avant-garde-gothic-pro-300, sans-serif;
  letter-spacing: 0.4px;
}
.vue-input input::placeholder {
  color: var(--color-primary);
  opacity: 1;
}
.vue-dropdown-item.highlighted {
  background-color: var(--color-primary-darker);
  color: var(--color-black);
}
.vue-dropdown-item.selected {
  background-color: var(--color-primary-darker);
  color: var(--color-black);
}
.vue-dropdown-item.highlighted.selected {
  background-color: var(--color-primary-darker);
  color: var(--color-black);
}
</style>

<template>
  <div class="gallery-image-container" @click="handleClick">
    <LazyImage
      :image="image.image033"
      :alt="`Portrait-${image.id}`"
      :useDefaultSize="true"
      :isGallery="true"
      @onLoad="handleOnLoad"
    />
  </div>
</template>

<script>
import LazyImage from '@/components/atoms/LazyImage.vue'
import breakpoints from '@/store/breakpoints'
import imageDetail from '@/store/imageDetail'

export default {
  name: 'GalleryImage',
  props: ['image'],
  emits: ['onLoad'],

  components: {
    LazyImage
  },

  setup(props, { emit }) {
    const handleClick = () => {
      imageDetail.openImage(props.image.id)
    }
    const handleOnLoad = () => {
      emit('onLoad', props.image.id)
    }

    return {
      handleOnLoad,
      handleClick,
      breakpoint: breakpoints.breakpoint
    }
  }
}
</script>

<style scoped>
.gallery-image-container {
  cursor: pointer;
}
</style>

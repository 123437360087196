<template>
  <section>
    <h2>Clothes and visage</h2>

    <div class="clothes-container -left">
      <div class="text-content">
        <h3>What not to wear?</h3>
        <p>
          I want to start with what not to wear. Never wear something that you
          have not tried before, that does not suit you and is not comfortable.
          The whole photoshoot would be one big unpleasant experience for you.
          There is nothing worse than wearing shoes that rub you or a sweater
          that bites. You will be unhappy with the whole photoshoot and it will
          be projected into photos. That is the last thing we want.
        </p>
      </div>
      <div
        v-if="breakpoint.value !== 'XS' && breakpoint.value !== 'SM'"
        class="clothes-image-container"
        :style="{
          'background-image': `url(${clothes_001})`,
          minWidth: `${IMAGE_WIDTH_MD}px`,
          minHeight: `${IMAGE_WIDTH_MD * 1.4}px`
        }"
      ></div>
    </div>

    <div class="clothes-container -right">
      <div
        v-if="breakpoint.value !== 'XS' && breakpoint.value !== 'SM'"
        class="clothes-image-container"
        :style="{
          'background-image': `url(${clothes_002})`,
          minWidth: `${IMAGE_WIDTH_MD}px`,
          minHeight: `${IMAGE_WIDTH_MD * 1.4}px`
        }"
      ></div>
      <div class="text-content">
        <h3>
          What should I wear for professional photos on LinkedIn, CV or for
          work?
        </h3>
        <p>
          I suggest more formal wear, but definitely, one that will make you
          comfortable. Try to think about usual things that you wear to work -
          if you don’t wear a suit at work, don’t wear it on our photoshoot. It
          should be clean and ironed clothes without logos and inscriptions,
          preferably in a single colour or with a decent pattern. Let me know in
          the form, what type of photoshoot and for what purpose it will be.
          Together we will fine-tune the entire outfit that will be the most
          matching for your photoshoot.
        </p>
      </div>
    </div>

    <div class="clothes-container -left">
      <div class="text-content">
        <h3>
          Clothes to wear for a lovestory or family photoshoot
        </h3>
        <p>
          The key for stunning photos in this case is to tune clothes together.
          You don't have to be all dressed the same, but I definitely recommend
          some details that you will have in common. For example, a strong
          colour. One of you can wear a T-shirt in the same colour, that other
          ones trousers. Another cute example is that you can have the same
          shoes. The possibilities are endless, but you have to complement each
          other. If one has formal clothes and the other one comes in
          sportswear, the photos will not be as good as they could be.
        </p>
        <p>
          Above all, you should enjoy the photoshoot and remember it with love.
          Photos should remind you of that amazing day with your significant
          other.
        </p>
      </div>
      <div
        v-if="breakpoint.value !== 'XS' && breakpoint.value !== 'SM'"
        class="clothes-image-container"
        :style="{
          'background-image': `url(${clothes_003})`,
          minWidth: `${IMAGE_WIDTH_MD}px`,
          minHeight: `${IMAGE_WIDTH_MD * 1.4}px`
        }"
      ></div>
    </div>

    <div
      v-if="breakpoint.value === 'XS' || breakpoint.value === 'SM'"
      class="mobile-image-strip"
    >
      <div
        class="clothes-image-container"
        :style="{
          'background-image': `url(${clothes_002})`,
          minWidth: `${IMAGE_WIDTH_XS}px`,
          minHeight: `${IMAGE_WIDTH_XS * 1.4}px`
        }"
      ></div>
      <div
        class="clothes-image-container"
        :style="{
          'background-image': `url(${clothes_003})`,
          minWidth: `${IMAGE_WIDTH_XS}px`,
          minHeight: `${IMAGE_WIDTH_XS * 1.4}px`
        }"
      ></div>
    </div>

    <div class="actions-container">
      <BaseButton
        class="primary mini"
        :onClick="() => $router.push({ name: 'contact' })"
      >
        Contact me
      </BaseButton>
    </div>
  </section>
</template>

<script>
import BaseButton from '@/components/atoms/BaseButton'
import breakpointStore from '@/store/breakpoints'
import images from '@/assets/images/images'
const IMAGE_WIDTH_XS = 140
const IMAGE_WIDTH_MD = 200

export default {
  name: 'BookViewIntro',
  components: {
    BaseButton
  },

  setup() {
    return {
      clothes_001: images.clothes_001,
      clothes_002: images.clothes_002,
      clothes_003: images.clothes_003,
      IMAGE_WIDTH_XS,
      IMAGE_WIDTH_MD,
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}
.actions-container {
  margin-top: 0px;
}

.clothes-container,
.mobile-image-strip {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.text-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.clothes-container.-left .text-content {
  margin-right: 3rem;
}
.clothes-container.-right .text-content {
  margin-left: 3rem;
}

.clothes-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  position: relative;
  margin: 5px;
}

h2 {
  margin-bottom: 2rem;
}
h3 {
  margin-bottom: 0.5rem;
}
p {
  text-align: justify;
}

@media (max-width: 600px) {
  .clothes-container {
    margin-bottom: 0;
  }
  .clothes-container.-left .text-content {
    margin-right: 0;
  }
  .clothes-container.-right .text-content {
    margin-left: 0;
  }
}
</style>

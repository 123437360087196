<template>
  <main>
    <Gallery :agregations="agregations" />
  </main>
</template>

<script>
import { ref } from 'vue'
import breakpointStore from '@/store/breakpoints'
import galleryApi from '@/services/galleryApi'
import Gallery from '@/components/gallery/Gallery.vue'

export default {
  name: 'CoupleView',
  components: {
    Gallery
  },

  async mounted() {
    this.agregations = galleryApi.fetchImages('couples')
  },

  setup() {
    const agregations = ref(null)

    return {
      agregations,
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
main {
  width: 100%;
  padding: 0;
}
</style>

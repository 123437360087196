<template><div></div></template>

<script>
import faqApi from '@/services/faqApi'

export default {
  name: 'BookViewGoogleFaq',

  async created() {
    const faqs = await faqApi.fetchFaq()
    const googleFaqs = await faqApi.fetchGoogleFaq()

    const jsonLd = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [...faqs, ...googleFaqs].map(faq => ({
        '@type': 'Question',
        name: faq.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.paragraph
        }
      }))
    }

    let tag = document.createElement('script')
    tag.innerHTML = JSON.stringify(jsonLd)
    tag.setAttribute('type', 'application/ld+json')
    document.head.appendChild(tag)
  },

  setup() {
    return {}
  }
}
</script>

<style scoped></style>

<template>
  <img
    :class="{
      '-large': isLarge,
      '-is-white': isWhite,
      '-mini': isMini,
      '-whatsapp': name === 'Whatsapp'
    }"
    :src="imageSrc"
    :alt="name"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  />
</template>

<script>
import images from '@/assets/images/images'
import { ref } from 'vue'

export default {
  name: 'SocialIcon',
  props: ['name', 'isLarge', 'isWhite', 'isMini'],

  setup(props) {
    const imageSrc = ref(
      props.isWhite
        ? images[`social${props.name}White`]
        : images[`social${props.name}`]
    )

    const handleMouseOver = () => {
      if (props.isWhite) {
        imageSrc.value = images[`social${props.name}Primary`]
      }
    }
    const handleMouseLeave = () => {
      if (props.isWhite) {
        imageSrc.value = images[`social${props.name}White`]
      }
    }

    return {
      handleMouseOver,
      handleMouseLeave,
      imageSrc
    }
  }
}
</script>

<style scoped>
img {
  width: 50px;
  height: 50px;
}
img.-mini {
  width: 35px;
  height: 35px;
}
img.-whatsapp {
  width: 28px;
  height: 28px;
  padding: 11px;
}

@media (max-width: 960px) {
  img {
    width: 40px;
    height: 40px;
  }
  img.-whatsapp {
    width: 22px;
    height: 22px;
    padding: 9px;
  }
  img.-mini.-whatsapp {
    width: 20px;
    height: 20px;
    padding: 7px;
  }
}
</style>

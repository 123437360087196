<template>
  <main>
    <div class="content-container">
      <p>
        <router-link class="in-text" to="/contact">
          Contact me directly
        </router-link>
        to receive more details on my services!
        <br />
        The rates listed below include tax and exclude travel fees.
      </p>

      <div class="services-container">
        <ServiceCard
          v-for="(service, index) in services"
          :service="service"
          :key="service.id"
          :type="index % 2 === 0 ? 'right' : 'left'"
        />
      </div>

      <div class="events-container">
        <p>
          Events, products and other photoshoots - please contact me via
          <a class="in-text" :onClick="() => handleClick('event')">
            contact form
          </a>
          for a custom quote.
        </p>
      </div>
    </div>
  </main>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import servicesApi from '@/services/servicesApi'
import breakpointStore from '@/store/breakpoints'
import ServiceCard from '@/components/services/ServiceCard.vue'
import bookStore from '@/store/book'

export default {
  name: 'ServicesView',
  components: {
    ServiceCard
  },

  async created() {
    this.services = await servicesApi.fetchServices()
  },

  setup() {
    const router = useRouter()
    const services = ref([])

    const handleClick = serviceId => {
      bookStore.setService(serviceId)
      router.push({ name: 'contact' })
    }

    return {
      services,
      breakpoint: breakpointStore.breakpoint,
      handleClick
    }
  }
}
</script>

<style scoped>
.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
a {
  color: var(--color-primary-darker);
  cursor: pointer;
  text-decoration: none;
}
p {
  text-align: center;
}
.events-container {
  padding: 1.5rem;
  background-color: var(--color-primary-darkest);
}
.events-container p {
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .services-container {
    margin-top: 0rem;
  }
}
</style>

<template>
  <main>
    <div class="content-container">
      <BaseButton
        class="primary links"
        :onClick="() => $router.push({ name: 'about' })"
      >
        About photo sessions
      </BaseButton>
      <BaseButtonLink
        class="primary links"
        link="https://petraproart.pixieset.com/booking/deposit"
      >
        Book a session in Amsterdam / Work with me
      </BaseButtonLink>
      <BaseButton
        class="primary links"
        :onClick="() => $router.push({ name: 'contact' })"
      >
        Contact me
      </BaseButton>
      <BaseButtonLink
        class="primary links"
        link="https://g.page/r/Cc5-CPdVCvE2EB0/review"
      >
        Leave me a Google review
      </BaseButtonLink>
      <BaseButton
        class="primary links"
        :onClick="() => $router.push({ name: 'stories' })"
      >
        Blog
      </BaseButton>

      <BaseButtonLink
        class="primary links"
        link="static/Amsterdam photo locations.pdf"
      >
        Best photo locations in Amsterdam
      </BaseButtonLink>
    </div>
  </main>
</template>

<script>
import images from '@/assets/images/images'
import BaseButton from '@/components/atoms/BaseButton'
import BaseButtonLink from '@/components/atoms/BaseButtonLink'
import breakpointStore from '@/store/breakpoints'

export default {
  name: 'AboutView',
  components: {
    BaseButton,
    BaseButtonLink
  },

  setup() {
    return {
      about: images.about,
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
.content-container {
  height: 100%;
  max-width: 500px;
  align-items: center;
  justify-content: center;
}
p {
  text-align: justify;
}

@media (max-width: 960px) {
  p {
    max-width: 450px;
  }
  .about-container {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}
@media (max-width: 960px) {
  .content-container {
    flex-direction: column;
  }
}
</style>

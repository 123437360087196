<template>
  <section>
    <h2>Location ideas</h2>
    <div class="locations-container">
      <router-link
        :to="`/location/${location.id}`"
        v-for="location in locations"
        :key="location.id"
      >
        <div
          class="location-container"
          :style="{
            'background-image': `url(${location.image})`,
            width: `${
              breakpoint.value === 'XS' || breakpoint.value === 'SM'
                ? IMAGE_SIZE_XS
                : IMAGE_SIZE_MD
            }px`,
            height: `${
              breakpoint.value === 'XS' || breakpoint.value === 'SM'
                ? IMAGE_SIZE_XS
                : IMAGE_SIZE_MD
            }px`
          }"
        >
          <h3>{{ location.title }}</h3>
        </div>
      </router-link>
    </div>
    <div class="actions-container">
      <BaseButton
        class="primary mini"
        :onClick="() => $router.push({ name: 'contact' })"
      >
        Contact me
      </BaseButton>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import locationsApi from '@/services/locationsApi'
import breakpointStore from '@/store/breakpoints'
import BaseButton from '@/components/atoms/BaseButton'

const IMAGE_SIZE_XS = 140
const IMAGE_SIZE_MD = 290

export default {
  name: 'BookViewLocations',
  components: {
    BaseButton
  },

  created() {
    this.locations = locationsApi.fetchLocations()
  },

  setup() {
    const locations = ref([])

    return {
      locations,
      IMAGE_SIZE_XS,
      IMAGE_SIZE_MD,
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
}
.locations-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.location-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  position: relative;
  margin: 5px;
}
h2 {
  margin-bottom: 2rem;
}
h3 {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 15px;
  right: 0;
  font-size: 0.8rem;
  color: var(--color-white);
  background: #0008;
}
a {
  color: var(--color-primary-dark);
  transition: all var(--transition-speed);
  text-decoration: none;
}
a:hover {
  color: var(--color-primary);
}
a:hover h3 {
  background: #0009;
}

@media (max-width: 600px) {
  h3 {
    font-size: 0.8rem;
    padding: 5px;
  }
}
@media (max-width: 960px) {
  .actions-container {
    margin-top: 2rem;
  }
}
</style>

<template>
  <main>
    <div class="content-container">
      <h1 v-html="story.title" />

      <div class="story-tags">
        <StoryTags :tags="story.tags" :date="story.date" />
      </div>

      <p>{{ story.summary }}</p>
      <div class="story" v-html="story.html"></div>

      <div class="flex">
        <img
          v-for="image in story.images"
          :src="
            [...portraits, ...couples].find(({ id }) => id === image.id)
              ?.image100
          "
          :key="image"
          :style="{
            width: image.isHorizontal ? 'calc(100% - 20px)' : 'calc(50% - 20px)'
          }"
        />
      </div>
    </div>
  </main>
</template>

<script>
import portraits from '@/assets/images/portfolio/portraits/portraits'
import couples from '@/assets/images/portfolio/couples/couples'
import stories from '@/services/blogApi'
import breakpointStore from '@/store/breakpoints'
import { useRoute } from 'vue-router'
import StoryTags from '@/components/stories/StoryTags.vue'

export default {
  name: 'StoryDetailView',
  components: { StoryTags },

  setup() {
    const route = useRoute()
    const { storyId } = route.params
    const story = stories.find(story => story.id === storyId)

    return {
      breakpoint: breakpointStore.breakpoint,
      story,
      portraits,
      couples
    }
  }
}
</script>

<style scoped>
main {
  padding-top: 1rem;
}
.content-container {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0rem;
}
h1 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.story-tags {
  margin-bottom: 1.5rem;
}

p {
  font-weight: 400;
}

.flex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.flex img {
  margin: 20px 10px;
}

@media (max-width: 600px) {
  .flex img {
    margin: 15px 7px;
    width: calc(100% - 14px) !important;
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import ErrorView from '../views/ErrorView.vue'
import HomeView from '../views/HomeView.vue'
import InstagramHackView from '../views/InstagramHackView.vue'
import LinksView from '../views/LinksView.vue'
import CoupleView from '../views/portfolio/CoupleView.vue'
import LocationView from '../views/portfolio/LocationView.vue'
import PortfolioView from '../views/portfolio/PortfolioView.vue'
import PortraitsView from '../views/portfolio/PortraitsView.vue'
import ServicesView from '../views/ServicesView.vue'
import StoriesView from '../views/StoriesView.vue'
import StoryDetailView from '../views/StoryDetailView.vue'

const isInstagram = navigator.userAgent.includes('Instagram')
const routes = [
  {
    path: '/:imageId?',
    name: 'home',
    component: HomeView,
    meta: { title: 'petrapro.art' }
  },

  {
    path: '/portfolio',
    name: 'portfolio',
    component: PortfolioView,
    meta: { title: 'Portfolio' }
  },
  {
    path: '/portraits/:imageId?',
    name: 'portraits',
    component: PortraitsView,
    meta: { title: 'Portraits' }
  },
  {
    path: '/couples/:imageId?',
    name: 'couples',
    component: CoupleView,
    meta: { title: 'Couples' }
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView,
    meta: { title: 'Services' }
  },
  {
    path: '/contact-me',
    name: 'contact',
    component: ContactView,
    meta: { title: 'Contact' }
  },
  {
    path: '/about-me',
    name: 'about',
    component: AboutView,
    meta: { title: 'About' }
  },
  {
    path: '/stories',
    name: 'stories',
    component: StoriesView,
    meta: { title: 'Stories' }
  },
  {
    path: '/stories/:storyId',
    name: 'story',
    component: StoryDetailView
  },
  {
    path: '/links',
    name: 'links',
    component: LinksView,
    meta: { title: 'Links' }
  },
  {
    path: '/about',
    name: 'aboutInstagram',
    component: isInstagram ? InstagramHackView : AboutView,
    meta: { instagramHackRoute: 'about-me' }
  },
  {
    path: '/contact',
    name: 'contactInstagram',
    component: isInstagram ? InstagramHackView : ContactView,
    meta: { instagramHackRoute: 'contact-me' }
  },
  {
    path: '/gallery',
    name: 'galleryInstagram',
    component: isInstagram ? InstagramHackView : HomeView,
    meta: { instagramHackRoute: '' }
  },

  {
    path: '/location/:locationId/:imageId?',
    name: 'location',
    component: LocationView,
    meta: { title: 'Location gallery' }
  },

  { path: '/:pathMatch(.*)*', name: 'error', component: ErrorView }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

const stories = [
  {
    id: 'adela-livio',
    title: 'Lovestory winter photoshoot <br /> at beach in Den Hague',
    tags: ['lovestory','photoshoot'],
    images: [
      { id: 'cpl-082', isHorizontal: true },
      { id: 'cpl-087', isHorizontal: true },
      { id: 'cpl-086', isHorizontal: true },
      { id: 'cpl-075', isHorizontal: true },
      { id: 'cpl-076', isHorizontal: true },
      { id: 'cpl-072', isHorizontal: true },
      { id: 'cpl-090', isHorizontal: true },
      { id: 'cpl-080', isHorizontal: true },
      { id: 'cpl-089', isHorizontal: true }
    ],
    date: 'February 10th, 2023',
    summary:
      'Adela and Livio celebrated their relationship with a Lovestory photoshoot at beach in Den Hague. I captured the couple’s love for each other, as well as their romantic walk along the beach. The photos taken were stunning and the couple will cherish the memories for years to come.',
    html: `
      <p>The winter evening was cold and crisp, but Adela and Livio were glowing with warmth and love. As the photographer, I had the privilege of capturing the special moment between them.</p>
      
      <p>Their eyes sparkled as they smiled for me, and I could tell that their love was genuine. I took some stunning shots of them walking along the beach, with the sun setting in the background. Adela and Livio were both dressed in beautiful winter clothes, and they looked incredibly romantic together.</p>
      
      <p>I was also able to capture some close-up shots of their faces, and I could see the love in their eyes. Adela and Livio were truly mesmerizing and I felt so lucky to be a part of their special day.</p>
      
      <p>At the end of the photoshoot, Adela and Livio shared a passionate kiss and I snapped away, capturing the moment perfectly. I was so proud of the photos I had taken, and I was sure that Adela and Livio would cherish the memories for years to come.</p>
      
      <p>This lovely portrait couple lovestory photographer experience was truly unforgettable, and I am grateful that I was able to capture it for them.</p>
      
      <br><br>
      
      <p class="center">Photos are a celebration of you and your relationship.</p>

      <p class="center">Don’t wait for the “right time” to take the photos of your love.</p>

      <h3 class="center">Look at the pictures below, stop for a moment, think, do you also want such photos with your loved one?</h3>

      <br>
      <p class="center">For more information about Lovestory photoshoot 
        <a class="in-text" href="/services">CLICK HERE</a>
      </p>
      `
  },
  {
    id: 'right-outfit',
    title: 'Struggling to choose the right outfit?',
    tags: ['inspiration'],
    images: [
      { id: 'cpl-057', isHorizontal: true },
      { id: 'cpl-038', isHorizontal: true },
      { id: 'cpl-061', isHorizontal: true },
      { id: 'cpl-015', isHorizontal: true },
      { id: 'cpl-032', isHorizontal: true }
    ],
    date: 'November 7th, 2022',
    summary:
      'The photoshoot is in front of you and you are thinking about what to wear. Before you start going through your wardrobe, think about what kind of photo shoot you have ahead of you, it will make your job much easier. Try asking yourself these questions and you will see that choosing clothes will be easy.',
    html: `
      <br>
      <p class="center">How many outfits will be photographed?</p>
      <p class="center">Will the photo be taken outside or inside?</p>
      <p class="center">Will you be taking pictures alone or with your partner or family?</p>
      <p class="center">Is it a photoshoot for personal or professional purposes?</p>
    
      <h3>What not to wear?</h3>

      <p>Only wear something that you have tried before, that does not suit you and is not comfortable. The whole photoshoot would be one big unpleasant experience for you. Nothing is worse than wearing shoes that rub you or a sweater that bites. You will be unhappy with the whole photoshoot and it will be projected into photos. That is the last thing we want.</p>

      <h3>What should I wear for professional photos on LinkedIn, CV, or work?</h3>

      <p>I suggest more formal wear, but definitely, one that will make you comfortable. Try to think about the usual things that you wear to work - if you don’t wear a suit at work, don’t wear it on our photoshoot. It should be clean and ironed clothes without logos and inscriptions, preferably in a single color or with a decent pattern. Let me know in the form, what type of photoshoot and for what purpose it will be. Together we will fine-tune the entire outfit that will be the most matching for your photoshoot.</p>

      <h3>Clothes to wear for a lovestory or family photoshoot</h3>

      <p>The key to stunning photos in this case is to tune clothes together. You don't have to be all dressed the same, but I recommend some details that you will have in common. For example, a strong color. One of you can wear a T-shirt in the same color, the other one trousers. Another cute example is that you can have the same shoes. The possibilities are endless, but it looks incredible if you complement each other. If one has formal clothes and the other comes in sportswear, the photos will not be as good as they could be.</p>

      <br>
      <p class="center">Above all, you should enjoy the photoshoot and remember it with love. Photos should remind you of that amazing day with your significant other.</p>

      <br>
      <p class="center">Do you have any questions, or need help with a specific problem?
        <a class="in-text" href="/contact-me">CONTACT ME</a>
      </p>
      `
  },
  {
    id: 'self-love',
    title: 'There is no right time for a self-love photoshoot',
    tags: ['inspiration'],
    images: [
      { id: 'prt-009', isHorizontal: false },
      { id: 'prt-010', isHorizontal: false },
      { id: 'prt-034', isHorizontal: false },
      { id: 'prt-039', isHorizontal: false },
      { id: 'prt-049', isHorizontal: false },
      { id: 'prt-050', isHorizontal: false }
    ],
    date: 'November 6th, 2022',
    summary:
      'You are still in one round. Work, cooking, cleaning, childcare, side business, meeting family and friends. Do you remember the last time you stopped and did something just for yourself? Try to stop for a moment. Prioritize yourself. Do something beautiful for yourself. Because you deserve it. Take photos of yourself to remember how you look today, and in years to come.',
    html: `
      <p>I put together a few points that will help you justify why you should celebrate your body right now.</p>
    
      <h4>It is empowering</h4>

      <p>You can freely explore different sides of yourself that make you more confident and secure in who you are. There is something wonderful about being able to embrace who you are and celebrate that by taking pictures of yourself.</p>

      <h4>Capture this stage of your life</h4>
      
      <p>Do you remember how beautiful you were 10 years ago? 20, 30? You can easily remember it yourself with photos. You just got engaged and you want to cherish this moment forever. Or have you decided it will be a baby? Right now is the perfect time to have your photo taken when you are expecting big changes in your life</p>
      
      <p>Every important moment for you deserves to be celebrated, choose the one that applies to you at the moment. If you are going through a separation or divorce then a little boost to your confidence is needed. Are you engaged or pregnant, after the birth, then it is the best time to celebrate your body with a photoshoot.</p>

      <h4>You will try what it's like to be a photo model</h4>
      
      <p>Have you ever wondered what it's like to be a model? Why not give it a try? Enjoy a day full of pampering, care, relaxation, and fun, and as a souvenir, you will have photos that will make you feel like a professional model.</p>
      
      <h4>You can romanticize your life a little and feel like the main character in your life</h4>

      <p>Fulfill your dream of being the main star of a movie called “My life” and create your own fascinating story. Take the time to appreciate your day-to-day life, no matter how ordinary might be because even the small things in your life are important and valuable to your happiness.</p>
      
      <h4>Step out of your comfort zone and do something gratifying for yourself</h4>

      <p>Is photoshoot a thing you have never done? Is it something out of your comfort zone? But you know you would love to have beautiful photos as you see on Instagram. And you know you will never have them if you stay in your comfort zone.An amazing photographer can help you with that. You need a little faith in how life will surprise you when you step out of your comfort zone.</p>

      <br><br>
      <p class="center">Photos are a celebration of you, your life, your journey, your relationships, and what you stand for. All the big and little moments important for you deserve to be honored. </p>

      <p class="center">Don’t wait for the “right time”.</p>

      <h3 class="center">When is the right time if not now?</h3>

      <br>
      <p class="center">For more information about Self-Love photoshoot 
        <a class="in-text" href="/services">CLICK HERE</a>
      </p>
      `
  }
]

export default stories

<template>
  <div v-if="state.status === 'loading'" class="content-container">
    <LoadingIcon />
  </div>

  <div v-else-if="state.status === 'success'" class="content-container">
    <p>The message was sent successfully.</p>
    <p>Thank you, I will respond to you in the shortest possible time!</p>
    <BaseButton class="primary" @click="() => $router.push({ name: 'home' })">
      Home
    </BaseButton>
  </div>

  <div v-else-if="state.status === 'error'" class="content-container">
    <p>There was a problem while sending the message.</p>
    <p>
      Please,
      <a href="javascript:;" @click="reloadPage">refresh</a>
      the page and try again.
    </p>
    <br />
    <p>
      If the problem persists, send me an email to
      <a href="mailto:info@petrapro.art">info@petrapro.art</a>
    </p>
    <BaseButton class="primary" @click="() => $router.push({ name: 'home' })">
      Home
    </BaseButton>
  </div>

  <form v-else>
    <div class="contact-container">
      <BaseInput
        v-model="state.name"
        type="text"
        label="Name*"
        placeholder="Your name (e.g. Petra)"
        :vuelidate="vuelidate.name"
      />
      <BaseInput
        v-model="state.email"
        type="email"
        label="Email*"
        placeholder="Your email (e.g. info@petrapro.art)"
        :vuelidate="vuelidate.email"
      />
    </div>

    <BaseInput
      v-model="state.message"
      element="textarea"
      label="Message*"
      placeholder="Your message"
      :vuelidate="vuelidate.message"
    />

    <BaseSelect
      v-model="state.service"
      :options="state.services"
      label="What service are you interested in?"
    />

    <div
      v-if="state.service && state.service !== 'Nothing yet, just message'"
      class="contact-container"
    >
      <BaseInput
        v-model="state.date"
        type="text"
        label="Do you have a date and time in mind?"
        subLabel="This will save us both some time, but we can handle it together in PM's."
        placeholder="8th of August after 10 am"
      />

      <BaseInput
        v-model="state.location"
        type="text"
        label="Do you have a location in mind?"
        subLabel="I can suggest great areas, but maybe you already have something in mind?"
        placeholder="Amsterdam, Centraal"
      />
    </div>

    <div
      v-if="breakpoint.value === 'XS' || breakpoint.value === 'SM'"
      class="button-container"
    >
      <BaseButton class="primary" @click="handleSubmit">
        Send
      </BaseButton>
    </div>
  </form>
</template>

<script>
import BaseInput from '@/components/atoms/BaseInput.vue'
import BaseSelect from '@/components/atoms/BaseSelect.vue'
import BaseButton from '@/components/atoms/BaseButton.vue'
import LoadingIcon from '@/components/atoms/LoadingIcon.vue'
import bookStore from '@/store/book'
import breakpoints from '@/store/breakpoints'

export default {
  name: 'BookForm',
  components: {
    BaseInput,
    BaseSelect,
    BaseButton,
    LoadingIcon
  },

  setup() {
    const reloadPage = () => {
      location.reload()
    }

    return {
      breakpoint: breakpoints.breakpoint,
      state: bookStore.formState,
      vuelidate: bookStore.vuelidate,
      handleSubmit: bookStore.handleSubmit,
      reloadPage
    }
  }
}
</script>

<style scoped>
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
}
.content-container p {
  text-align: center;
}
.content-container p:last-of-type {
  margin-bottom: 3rem;
}
a {
  font-family: museo-slab, serif;
}

.contact-container {
  display: flex;
}
.contact-container > div:first-child {
  width: 50%;
  margin-right: 1rem;
}
.contact-container > div:last-child {
  width: 50%;
}

.button-container {
  margin-top: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 600px) {
  .contact-container {
    flex-direction: column;
  }
  .contact-container > div:first-child {
    width: 100%;
    margin-right: 0;
  }
  .contact-container > div:last-child {
    width: 100%;
  }
}
</style>

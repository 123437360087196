<template>
  <NavMobile
    v-if="
      breakpoint.value === 'XS' ||
        breakpoint.value === 'SM' ||
        breakpoint.value === 'MD'
    "
  />
  <Nav v-else :isHome="$route.name === 'home'" />

  <router-view />

  <Footer :isHome="$route.name === 'home'" />
  <GalleryImageDetail
    v-if="!!$route.params.imageId"
    :imageId="$route.params.imageId"
  />
</template>

<script>
import '@/assets/css/animate.css'
import '@/assets/css/reset.css'
import '@/assets/css/variables.css'
import router from '@/router/index'
import breakpoints from '@/store/breakpoints'
import imageDetail from '@/store/imageDetail'
import 'vue-next-select/dist/index.css'

import GalleryImageDetail from '@/components/gallery/GalleryImageDetail'
import Footer from '@/components/nav/Footer'
import Nav from '@/components/nav/Nav'
import NavMobile from '@/components/nav/NavMobile'

export default {
  name: 'App',
  components: {
    Nav,
    NavMobile,
    Footer,
    GalleryImageDetail
  },

  mounted() {
    breakpoints.initializeBreakPoint()
    window.addEventListener('resize', () => {
      breakpoints.initializeBreakPoint()
    })

    window.addEventListener('keyup', function(evt) {
      if (evt.keyCode === 27) {
        imageDetail.closeImage()
      }
      if (evt.keyCode === 37) {
        imageDetail.previousImage(router.currentRoute.value.params.imageId)
      }
      if (evt.keyCode === 39) {
        imageDetail.nextImage(router.currentRoute.value.params.imageId)
      }
    })
  },

  setup() {
    return {
      breakpoint: breakpoints.breakpoint,
      state: imageDetail.state
    }
  }
}
</script>

<style>
html,
body,
#app {
  margin: 0;
  padding: 0;

  height: 100%;
  width: 100%;
  overflow: hidden;
}
#app {
  text-align: center;
  color: var(--color-primary);
  background-color: var(--color-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: museo-slab, serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
}

main {
  position: fixed;
  top: var(--nav-height);
  bottom: var(--footer-height);
  padding: 3rem 2rem 0rem 2rem;
  margin: auto;

  width: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
}

h1,
h2,
h3 {
  margin: 0;
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
}
h1 {
  margin-top: 1.5rem;
  margin-bottom: 3rem;

  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 2px;
}
h2 {
  margin-bottom: 0.7rem;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 1px;
}
h3 {
  font-size: 1.05rem;
}

p {
  margin: 0;
  margin-bottom: 1rem;
  text-align: left;

  color: var(--color-primary);
  font-family: museo-slab, serif;
  font-weight: 600;
  font-style: normal;
}
.story p {
  font-weight: 400;
}
.story h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.story h4 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.story .center {
  text-align: center;
}

input,
textarea,
.vue-input input {
  font-family: museo-slab, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}
textarea {
  resize: vertical;
}
a,
label {
  font-family: politica-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
}
label {
  font-weight: 700;
  font-size: 1.1rem;
}
a.in-text {
  font-family: museo-slab, serif;
  font-weight: 600;
  color: var(--color-primary-dark);
  text-decoration: none;
  transition: all var(--transition-speed);
  letter-spacing: 0px;
}
a.in-text:hover {
  color: var(--color-primary);
}

.content-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  text-align: left;
  max-width: 900px;
  width: 100%;
}
.actions-container {
  margin: 2rem auto;
}

@media (max-width: 960px) {
  h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;

    font-size: 1.2rem;
  }
  h2 {
    font-size: 1rem;
  }
  main {
    padding: 0rem 1.5rem 0rem 1.5rem;
    width: calc(100% - 3rem);
  }
  input,
  textarea,
  .vue-input input {
    font-family: museo-slab, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9rem;
  }
  label {
    font-size: 0.9rem;
  }

  .content-container {
    margin-bottom: 0rem;
  }
  .actions-container {
    margin: 0rem auto 1rem auto;
    padding-bottom: 1rem;
  }
}
</style>

<template>
  <a target="_blank" rel="noreferrer" class="button" :href="link">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'BaseButtonLink',
  props: {
    link: { type: String }
  }
}
</script>

<style scoped>
a {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 1;
  box-sizing: border-box;
  text-decoration: none;
}
</style>

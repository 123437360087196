<template>
  <aside :class="{ '-is-footer': isFooter, '-is-white': isWhite }">
    <a
      href="https://www.instagram.com/petrapro.art"
      noreferrer
      noopener
      rel="noreferrer"
      target="_blank"
    >
      <SocialIcon
        :name="'Instagram'"
        :isWhite="isWhite"
        :isMini="
          isFooter && (breakpoint.value === 'XS' || breakpoint.value === 'SM')
        "
      />
    </a>
    <a
      href="https://www.pinterest.com/petraproart/"
      noreferrer
      noopener
      rel="noreferrer"
      target="_blank"
    >
      <SocialIcon
        :name="'Pinterest'"
        :isWhite="isWhite"
        :isMini="
          isFooter && (breakpoint.value === 'XS' || breakpoint.value === 'SM')
        "
      />
    </a>
    <a href="mailto:info@petrapro.art" noreferrer noopener>
      <SocialIcon
        :name="'Mail'"
        :isWhite="isWhite"
        :isMini="
          isFooter && (breakpoint.value === 'XS' || breakpoint.value === 'SM')
        "
      />
    </a>
    <a href="https://wa.me/420721802703 " noreferrer noopener>
      <SocialIcon
        :name="'Whatsapp'"
        :isWhite="isWhite"
        :isMini="
          isFooter && (breakpoint.value === 'XS' || breakpoint.value === 'SM')
        "
      />
    </a>
  </aside>
</template>

<script>
import SocialIcon from '@/components/nav/SocialIcon.vue'
import breakpoints from '@/store/breakpoints'

export default {
  name: 'Socials',
  props: ['isWhite', 'isFooter'],
  components: {
    SocialIcon
  },

  setup() {
    return {
      breakpoint: breakpoints.breakpoint
    }
  }
}
</script>

<style scoped>
aside {
  display: flex;
}
a {
  margin-right: 0.7rem;
  transition: all var(--transition-speed);
}
a:last-child {
  margin-right: 0;
}
a:hover {
  opacity: var(--opacity-hover);
}
aside.-is-white a:hover {
  opacity: 1;
}

aside.-is-footer {
  justify-content: center;
}
aside.-is-footer a {
  opacity: var(--opacity-hover);
}
aside.-is-footer a:hover {
  opacity: 1;
}

@media (max-width: 600px) {
  aside.-is-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  aside.-is-footer a {
    margin-right: 0;
  }
}
</style>

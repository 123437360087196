import emailjs from 'emailjs-com'

const SERVICE_ID = 'gmail_service'
const TEMPLATE_ID = 'contact_form'
const EMAIL_JS_ID = 'user_SetmcShTbq5IyAWqRwlqw'

const sendEmail = async ({
  user_email,
  user_name,
  user_message,
  user_service,
  user_date,
  user_location
}) => {
  try {
    return await emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID,
      {
        user_email,
        user_name,
        user_message,
        user_service,
        user_date,
        user_location
      },
      EMAIL_JS_ID
    )
  } catch (error) {
    console.error(error)
    return {
      status: 400,
      error
    }
  }
}

export default {
  sendEmail
}

import couples_001_100 from './100/couples-001.jpg'
import couples_001_033 from './033/couples-001.jpg'
import couples_002_100 from './100/couples-002.jpg'
import couples_002_033 from './033/couples-002.jpg'
import couples_003_100 from './100/couples-003.jpg'
import couples_003_033 from './033/couples-003.jpg'
import couples_004_100 from './100/couples-004.jpg'
import couples_004_033 from './033/couples-004.jpg'
import couples_005_100 from './100/couples-005.jpg'
import couples_005_033 from './033/couples-005.jpg'
import couples_006_100 from './100/couples-006.jpg'
import couples_006_033 from './033/couples-006.jpg'
import couples_007_100 from './100/couples-007.jpg'
import couples_007_033 from './033/couples-007.jpg'
import couples_008_100 from './100/couples-008.jpg'
import couples_008_033 from './033/couples-008.jpg'
import couples_009_100 from './100/couples-009.jpg'
import couples_009_033 from './033/couples-009.jpg'
import couples_010_100 from './100/couples-010.jpg'
import couples_010_033 from './033/couples-010.jpg'
import couples_012_100 from './100/couples-012.jpg'
import couples_012_033 from './033/couples-012.jpg'
import couples_013_100 from './100/couples-013.jpg'
import couples_013_033 from './033/couples-013.jpg'
import couples_014_100 from './100/couples-014.jpg'
import couples_014_033 from './033/couples-014.jpg'
import couples_015_100 from './100/couples-015.jpg'
import couples_015_033 from './033/couples-015.jpg'
import couples_016_100 from './100/couples-016.jpg'
import couples_016_033 from './033/couples-016.jpg'
import couples_017_100 from './100/couples-017.jpg'
import couples_017_033 from './033/couples-017.jpg'
import couples_018_100 from './100/couples-018.jpg'
import couples_018_033 from './033/couples-018.jpg'
import couples_019_100 from './100/couples-019.jpg'
import couples_019_033 from './033/couples-019.jpg'
import couples_020_100 from './100/couples-020.jpg'
import couples_020_033 from './033/couples-020.jpg'
import couples_021_100 from './100/couples-021.jpg'
import couples_021_033 from './033/couples-021.jpg'
import couples_022_100 from './100/couples-022.jpg'
import couples_022_033 from './033/couples-022.jpg'
import couples_023_100 from './100/couples-023.jpg'
import couples_023_033 from './033/couples-023.jpg'
import couples_024_100 from './100/couples-024.jpg'
import couples_024_033 from './033/couples-024.jpg'
import couples_025_100 from './100/couples-025.jpg'
import couples_025_033 from './033/couples-025.jpg'
import couples_026_100 from './100/couples-026.jpg'
import couples_026_033 from './033/couples-026.jpg'
import couples_027_100 from './100/couples-027.jpg'
import couples_027_033 from './033/couples-027.jpg'
import couples_028_100 from './100/couples-028.jpg'
import couples_028_033 from './033/couples-028.jpg'
import couples_029_100 from './100/couples-029.jpg'
import couples_029_033 from './033/couples-029.jpg'
import couples_030_100 from './100/couples-030.jpg'
import couples_030_033 from './033/couples-030.jpg'
import couples_031_100 from './100/couples-031.jpg'
import couples_031_033 from './033/couples-031.jpg'
import couples_032_100 from './100/couples-032.jpg'
import couples_032_033 from './033/couples-032.jpg'
import couples_033_100 from './100/couples-033.jpg'
import couples_033_033 from './033/couples-033.jpg'
import couples_034_100 from './100/couples-034.jpg'
import couples_034_033 from './033/couples-034.jpg'
import couples_035_100 from './100/couples-035.jpg'
import couples_035_033 from './033/couples-035.jpg'
import couples_036_100 from './100/couples-036.jpg'
import couples_036_033 from './033/couples-036.jpg'
import couples_037_100 from './100/couples-037.jpg'
import couples_037_033 from './033/couples-037.jpg'
import couples_038_100 from './100/couples-038.jpg'
import couples_038_033 from './033/couples-038.jpg'
import couples_039_100 from './100/couples-039.jpg'
import couples_039_033 from './033/couples-039.jpg'
import couples_040_100 from './100/couples-040.jpg'
import couples_040_033 from './033/couples-040.jpg'
import couples_041_100 from './100/couples-041.jpg'
import couples_041_033 from './033/couples-041.jpg'
import couples_042_100 from './100/couples-042.jpg'
import couples_042_033 from './033/couples-042.jpg'
import couples_043_100 from './100/couples-043.jpg'
import couples_043_033 from './033/couples-043.jpg'
import couples_044_100 from './100/couples-044.jpg'
import couples_044_033 from './033/couples-044.jpg'
import couples_045_100 from './100/couples-045.jpg'
import couples_045_033 from './033/couples-045.jpg'
import couples_046_100 from './100/couples-046.jpg'
import couples_046_033 from './033/couples-046.jpg'
import couples_047_100 from './100/couples-047.jpg'
import couples_047_033 from './033/couples-047.jpg'
import couples_048_100 from './100/couples-048.jpg'
import couples_048_033 from './033/couples-048.jpg'
import couples_049_100 from './100/couples-049.jpg'
import couples_049_033 from './033/couples-049.jpg'
import couples_050_100 from './100/couples-050.jpg'
import couples_050_033 from './033/couples-050.jpg'
import couples_051_100 from './100/couples-051.jpg'
import couples_051_033 from './033/couples-051.jpg'
import couples_052_100 from './100/couples-052.jpg'
import couples_052_033 from './033/couples-052.jpg'
import couples_053_100 from './100/couples-053.jpg'
import couples_053_033 from './033/couples-053.jpg'
import couples_054_100 from './100/couples-054.jpg'
import couples_054_033 from './033/couples-054.jpg'
import couples_055_100 from './100/couples-055.jpg'
import couples_055_033 from './033/couples-055.jpg'
import couples_056_100 from './100/couples-056.jpg'
import couples_056_033 from './033/couples-056.jpg'
import couples_057_100 from './100/couples-057.jpg'
import couples_057_033 from './033/couples-057.jpg'
import couples_058_100 from './100/couples-058.jpg'
import couples_058_033 from './033/couples-058.jpg'
import couples_059_100 from './100/couples-059.jpg'
import couples_059_033 from './033/couples-059.jpg'
import couples_060_100 from './100/couples-060.jpg'
import couples_060_033 from './033/couples-060.jpg'
import couples_061_100 from './100/couples-061.jpg'
import couples_061_033 from './033/couples-061.jpg'
import couples_062_100 from './100/couples-062.jpg'
import couples_062_033 from './033/couples-062.jpg'
import couples_063_100 from './100/couples-063.jpg'
import couples_063_033 from './033/couples-063.jpg'
import couples_064_100 from './100/couples-064.jpg'
import couples_064_033 from './033/couples-064.jpg'
import couples_065_100 from './100/couples-065.jpg'
import couples_065_033 from './033/couples-065.jpg'
import couples_066_100 from './100/couples-066.jpg'
import couples_066_033 from './033/couples-066.jpg'
import couples_067_100 from './100/couples-067.jpg'
import couples_067_033 from './033/couples-067.jpg'
import couples_068_100 from './100/couples-068.jpg'
import couples_068_033 from './033/couples-068.jpg'
import couples_069_100 from './100/couples-069.jpg'
import couples_069_033 from './033/couples-069.jpg'
import couples_070_100 from './100/couples-070.jpg'
import couples_070_033 from './033/couples-070.jpg'
import couples_071_100 from './100/couples-071.jpg'
import couples_071_033 from './033/couples-071.jpg'
import couples_072_100 from './100/couples-072.jpg'
import couples_072_033 from './033/couples-072.jpg'
import couples_073_100 from './100/couples-073.jpg'
import couples_073_033 from './033/couples-073.jpg'
import couples_074_100 from './100/couples-074.jpg'
import couples_074_033 from './033/couples-074.jpg'
import couples_075_100 from './100/couples-075.jpg'
import couples_075_033 from './033/couples-075.jpg'
import couples_076_100 from './100/couples-076.jpg'
import couples_076_033 from './033/couples-076.jpg'
import couples_077_100 from './100/couples-077.jpg'
import couples_077_033 from './033/couples-077.jpg'
import couples_078_100 from './100/couples-078.jpg'
import couples_078_033 from './033/couples-078.jpg'
import couples_079_100 from './100/couples-079.jpg'
import couples_079_033 from './033/couples-079.jpg'
import couples_080_100 from './100/couples-080.jpg'
import couples_080_033 from './033/couples-080.jpg'
import couples_081_100 from './100/couples-081.jpg'
import couples_081_033 from './033/couples-081.jpg'
import couples_082_100 from './100/couples-082.jpg'
import couples_082_033 from './033/couples-082.jpg'
import couples_083_100 from './100/couples-083.jpg'
import couples_083_033 from './033/couples-083.jpg'
import couples_084_100 from './100/couples-084.jpg'
import couples_084_033 from './033/couples-084.jpg'
import couples_085_100 from './100/couples-085.jpg'
import couples_085_033 from './033/couples-085.jpg'
import couples_086_100 from './100/couples-086.jpg'
import couples_086_033 from './033/couples-086.jpg'
import couples_087_100 from './100/couples-087.jpg'
import couples_087_033 from './033/couples-087.jpg'
import couples_088_100 from './100/couples-088.jpg'
import couples_088_033 from './033/couples-088.jpg'
import couples_089_100 from './100/couples-089.jpg'
import couples_089_033 from './033/couples-089.jpg'
import couples_090_100 from './100/couples-090.jpg'
import couples_090_033 from './033/couples-090.jpg'

// Priority: 0-10 (0 - not visible, 1 - min, 10 - max)
// Locations: oosterpark, ndsm, amsterdam, knsm, world, gems

export default [
  {
    id: 'cpl-001',
    image033: couples_001_033,
    image100: couples_001_100,
    priority: 5,
    location: 'gems'
  },
  {
    id: 'cpl-002',
    image033: couples_002_033,
    image100: couples_002_100,
    priority: 6,
    location: 'gems'
  },
  {
    id: 'cpl-003',
    image033: couples_003_033,
    image100: couples_003_100,
    priority: 4,
    location: ''
  },
  {
    id: 'cpl-004',
    image033: couples_004_033,
    image100: couples_004_100,
    priority: 4,
    location: ''
  },
  {
    id: 'cpl-005',
    image033: couples_005_033,
    image100: couples_005_100,
    priority: 5,
    location: ''
  },
  {
    id: 'cpl-006',
    image033: couples_006_033,
    image100: couples_006_100,
    priority: 4,
    location: ''
  },
  {
    id: 'cpl-007',
    image033: couples_007_033,
    image100: couples_007_100,
    priority: 3,
    location: ''
  },
  {
    id: 'cpl-008',
    image033: couples_008_033,
    image100: couples_008_100,
    priority: 5,
    location: 'world'
  },
  {
    id: 'cpl-009',
    image033: couples_009_033,
    image100: couples_009_100,
    priority: 2,
    location: ''
  },
  {
    id: 'cpl-010',
    image033: couples_010_033,
    image100: couples_010_100,
    priority: 4,
    location: 'world'
  },
  {
    id: 'cpl-012',
    image033: couples_012_033,
    image100: couples_012_100,
    priority: 3,
    location: 'world'
  },
  {
    id: 'cpl-013',
    image033: couples_013_033,
    image100: couples_013_100,
    priority: 9,
    location: 'ndsm'
  },
  {
    id: 'cpl-014',
    image033: couples_014_033,
    image100: couples_014_100,
    priority: 5,
    location: 'ndsm'
  },
  {
    id: 'cpl-015',
    image033: couples_015_033,
    image100: couples_015_100,
    priority: 6,
    location: 'ndsm'
  },
  {
    id: 'cpl-016',
    image033: couples_016_033,
    image100: couples_016_100,
    priority: 6,
    location: 'ndsm'
  },
  {
    id: 'cpl-017',
    image033: couples_017_033,
    image100: couples_017_100,
    priority: 7,
    location: 'ndsm'
  },
  {
    id: 'cpl-018',
    image033: couples_018_033,
    image100: couples_018_100,
    priority: 5,
    location: 'ndsm'
  },
  {
    id: 'cpl-019',
    image033: couples_019_033,
    image100: couples_019_100,
    priority: 6,
    location: 'ndsm'
  },
  {
    id: 'cpl-020',
    image033: couples_020_033,
    image100: couples_020_100,
    priority: 7,
    location: 'ndsm'
  },
  {
    id: 'cpl-021',
    image033: couples_021_033,
    image100: couples_021_100,
    priority: 3,
    location: 'ndsm'
  },
  {
    id: 'cpl-022',
    image033: couples_022_033,
    image100: couples_022_100,
    priority: 4,
    location: 'ndsm'
  },
  {
    id: 'cpl-023',
    image033: couples_023_033,
    image100: couples_023_100,
    priority: 5,
    location: 'ndsm'
  },
  {
    id: 'cpl-024',
    image033: couples_024_033,
    image100: couples_024_100,
    priority: 6,
    location: 'amsterdam'
  },
  {
    id: 'cpl-025',
    image033: couples_025_033,
    image100: couples_025_100,
    priority: 9,
    location: 'amsterdam'
  },
  {
    id: 'cpl-026',
    image033: couples_026_033,
    image100: couples_026_100,
    priority: 7,
    location: 'amsterdam'
  },
  {
    id: 'cpl-027',
    image033: couples_027_033,
    image100: couples_027_100,
    priority: 3,
    location: ''
  },
  {
    id: 'cpl-028',
    image033: couples_028_033,
    image100: couples_028_100,
    priority: 8,
    location: 'amsterdam'
  },
  {
    id: 'cpl-029',
    image033: couples_029_033,
    image100: couples_029_100,
    priority: 9,
    location: 'amsterdam'
  },
  {
    id: 'cpl-030',
    image033: couples_030_033,
    image100: couples_030_100,
    priority: 6,
    location: 'amsterdam'
  },
  {
    id: 'cpl-031',
    image033: couples_031_033,
    image100: couples_031_100,
    priority: 6,
    location: 'amsterdam'
  },
  {
    id: 'cpl-032',
    image033: couples_032_033,
    image100: couples_032_100,
    priority: 8,
    location: 'amsterdam'
  },
  {
    id: 'cpl-033',
    image033: couples_033_033,
    image100: couples_033_100,
    priority: 7,
    location: 'amsterdam'
  },
  {
    id: 'cpl-034',
    image033: couples_034_033,
    image100: couples_034_100,
    priority: 4,
    location: 'amsterdam'
  },
  {
    id: 'cpl-035',
    image033: couples_035_033,
    image100: couples_035_100,
    priority: 7,
    location: 'knsm'
  },
  {
    id: 'cpl-036',
    image033: couples_036_033,
    image100: couples_036_100,
    priority: 8,
    location: 'knsm'
  },
  {
    id: 'cpl-037',
    image033: couples_037_033,
    image100: couples_037_100,
    priority: 6,
    location: 'knsm'
  },
  {
    id: 'cpl-038',
    image033: couples_038_033,
    image100: couples_038_100,
    priority: 10,
    location: 'knsm'
  },
  {
    id: 'cpl-039',
    image033: couples_039_033,
    image100: couples_039_100,
    priority: 10,
    location: 'knsm'
  },
  {
    id: 'cpl-040',
    image033: couples_040_033,
    image100: couples_040_100,
    priority: 9,
    location: 'knsm'
  },
  {
    id: 'cpl-041',
    image033: couples_041_033,
    image100: couples_041_100,
    priority: 8,
    location: 'knsm'
  },
  {
    id: 'cpl-042',
    image033: couples_042_033,
    image100: couples_042_100,
    priority: 8,
    location: 'knsm'
  },
  {
    id: 'cpl-043',
    image033: couples_043_033,
    image100: couples_043_100,
    priority: 9,
    location: 'knsm'
  },
  {
    id: 'cpl-044',
    image033: couples_044_033,
    image100: couples_044_100,
    priority: 7,
    location: 'knsm'
  },
  {
    id: 'cpl-045',
    image033: couples_045_033,
    image100: couples_045_100,
    priority: 7,
    location: 'knsm'
  },
  {
    id: 'cpl-046',
    image033: couples_046_033,
    image100: couples_046_100,
    priority: 10,
    location: 'oosterpark'
  },
  {
    id: 'cpl-047',
    image033: couples_047_033,
    image100: couples_047_100,
    priority: 7,
    location: 'oosterpark'
  },
  {
    id: 'cpl-048',
    image033: couples_048_033,
    image100: couples_048_100,
    priority: 8,
    location: 'oosterpark'
  },
  {
    id: 'cpl-049',
    image033: couples_049_033,
    image100: couples_049_100,
    priority: 8,
    location: 'oosterpark'
  },
  {
    id: 'cpl-050',
    image033: couples_050_033,
    image100: couples_050_100,
    priority: 9,
    location: 'oosterpark'
  },
  {
    id: 'cpl-051',
    image033: couples_051_033,
    image100: couples_051_100,
    priority: 5,
    location: 'oosterpark'
  },
  {
    id: 'cpl-052',
    image033: couples_052_033,
    image100: couples_052_100,
    priority: 6,
    location: 'oosterpark'
  },
  {
    id: 'cpl-053',
    image033: couples_053_033,
    image100: couples_053_100,
    priority: 5,
    location: 'oosterpark'
  },
  {
    id: 'cpl-054',
    image033: couples_054_033,
    image100: couples_054_100,
    priority: 3,
    location: 'oosterpark'
  },
  {
    id: 'cpl-055',
    image033: couples_055_033,
    image100: couples_055_100,
    priority: 9,
    location: 'oosterpark'
  },
  {
    id: 'cpl-056',
    image033: couples_056_033,
    image100: couples_056_100,
    priority: 5,
    location: 'oosterpark'
  },
  {
    id: 'cpl-057',
    image033: couples_057_033,
    image100: couples_057_100,
    priority: 10,
    location: 'oosterpark'
  },
  {
    id: 'cpl-058',
    image033: couples_058_033,
    image100: couples_058_100,
    priority: 10,
    location: 'oosterpark'
  },
  {
    id: 'cpl-059',
    image033: couples_059_033,
    image100: couples_059_100,
    priority: 7,
    location: 'oosterpark'
  },
  {
    id: 'cpl-060',
    image033: couples_060_033,
    image100: couples_060_100,
    priority: 6,
    location: 'gems'
  },
  {
    id: 'cpl-061',
    image033: couples_061_033,
    image100: couples_061_100,
    priority: 9,
    location: 'gems'
  },
  {
    id: 'cpl-062',
    image033: couples_062_033,
    image100: couples_062_100,
    priority: 9,
    location: 'gems'
  },
  {
    id: 'cpl-063',
    image033: couples_063_033,
    image100: couples_063_100,
    priority: 10,
    location: 'gems'
  },
  {
    id: 'cpl-064',
    image033: couples_064_033,
    image100: couples_064_100,
    priority: 7,
    location: 'gems'
  },
  {
    id: 'cpl-065',
    image033: couples_065_033,
    image100: couples_065_100,
    priority: 8,
    location: 'knsm'
  },
  {
    id: 'cpl-066',
    image033: couples_066_033,
    image100: couples_066_100,
    priority: 7,
    location: 'knsm'
  },
  {
    id: 'cpl-067',
    image033: couples_067_033,
    image100: couples_067_100,
    priority: 6,
    location: 'knsm'
  },
  {
    id: 'cpl-068',
    image033: couples_068_033,
    image100: couples_068_100,
    priority: 9,
    location: 'knsm'
  },
  {
    id: 'cpl-069',
    image033: couples_069_033,
    image100: couples_069_100,
    priority: 7,
    location: 'knsm'
  },
  {
    id: 'cpl-070',
    image033: couples_070_033,
    image100: couples_070_100,
    priority: 5,
    location: 'knsm'
  },
  {
    id: 'cpl-071',
    image033: couples_071_033,
    image100: couples_071_100,
    priority: 9,
    location: 'knsm'
  },
  {
    id: 'cpl-072',
    image033: couples_072_033,
    image100: couples_072_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-073',
    image033: couples_073_033,
    image100: couples_073_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-074',
    image033: couples_074_033,
    image100: couples_074_100,
    priority: 10,
    location: 'world'
  },
  {
    id: 'cpl-075',
    image033: couples_075_033,
    image100: couples_075_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-076',
    image033: couples_076_033,
    image100: couples_076_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-077',
    image033: couples_077_033,
    image100: couples_077_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-078',
    image033: couples_078_033,
    image100: couples_078_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-079',
    image033: couples_079_033,
    image100: couples_079_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-080',
    image033: couples_080_033,
    image100: couples_080_100,
    priority: 10,
    location: 'world'
  },
  {
    id: 'cpl-081',
    image033: couples_081_033,
    image100: couples_081_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-082',
    image033: couples_082_033,
    image100: couples_082_100,
    priority: 10,
    location: 'world'
  },
  {
    id: 'cpl-083',
    image033: couples_083_033,
    image100: couples_083_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-084',
    image033: couples_084_033,
    image100: couples_084_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-085',
    image033: couples_085_033,
    image100: couples_085_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-086',
    image033: couples_086_033,
    image100: couples_086_100,
    priority: 10,
    location: 'world'
  },
  {
    id: 'cpl-087',
    image033: couples_087_033,
    image100: couples_087_100,
    priority: 10,
    location: 'world'
  },
  {
    id: 'cpl-088',
    image033: couples_088_033,
    image100: couples_088_100,
    priority: 9,
    location: 'world'
  },
  {
    id: 'cpl-089',
    image033: couples_089_033,
    image100: couples_089_100,
    priority: 10,
    location: 'world'
  },
  {
    id: 'cpl-090',
    image033: couples_090_033,
    image100: couples_090_100,
    priority: 10,
    location: 'world'
  }
]

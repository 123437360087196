import { ref } from 'vue'
import router from '@/router/index'
import galleryApi from '@/services/galleryApi'

const images = ref(galleryApi.allImages)

const getImage = imageId => {
  const relevantImages =
    images.value[
      router.currentRoute.value.name === 'location'
        ? router.currentRoute.value.params.locationId
        : router.currentRoute.value.name
    ]
  return relevantImages.find(image => image.id === imageId)
}

const closeImage = () => {
  router.push({ params: { imageId: '' } })
}
const openImage = imageId => {
  router.push({ params: { imageId } })
}

const nextImage = activeImageId => {
  const relevantImages =
    images.value[
      router.currentRoute.value.name === 'location'
        ? router.currentRoute.value.params.locationId
        : router.currentRoute.value.name
    ]
  const activeImageIndex = relevantImages.findIndex(
    image => image.id === activeImageId
  )
  const nextImage = relevantImages[activeImageIndex + 1] || relevantImages[0]
  router.replace({ params: { imageId: nextImage.id } })
}
const previousImage = activeImageId => {
  const relevantImages =
    images.value[
      router.currentRoute.value.name === 'location'
        ? router.currentRoute.value.params.locationId
        : router.currentRoute.value.name
    ]
  const activeImageIndex = relevantImages.findIndex(
    image => image.id === activeImageId
  )
  const previousImage =
    relevantImages[activeImageIndex - 1] ||
    relevantImages[relevantImages.length - 1]
  router.replace({ params: { imageId: previousImage.id } })
}

export default {
  images,
  getImage,

  closeImage,
  openImage,

  nextImage,
  previousImage
}

<template>
  <div class="gallery-image-detail-container" @click="handleClose">
    <div class="close-container link">
      <img :src="imgCross" @click="handleClose" alt="Close photo" />
    </div>

    <div class="image-container">
      <img :src="image.image100" alt="alt" />
    </div>

    <div class="controls-container">
      <div class="link" @click="handlePreviousPhoto">
        <img :src="imgArrowLeft" alt="Previous photo" />
      </div>
      <div class="link" @click="handleNextPhoto">
        <img :src="imgArrowRight" alt="Next photo" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import images from '@/assets/images/images'
import breakpoints from '@/store/breakpoints'
import imageDetail from '@/store/imageDetail'

export default {
  name: 'GalleryImageDetail',
  props: ['imageId'],
  emits: ['onLoad'],

  setup(props) {
    const image = computed(() => imageDetail.getImage(props.imageId))

    const handleClose = () => {
      imageDetail.closeImage()
    }
    const handleNextPhoto = event => {
      event.stopPropagation()
      imageDetail.nextImage(props.imageId)
    }
    const handlePreviousPhoto = () => {
      event.stopPropagation()
      imageDetail.previousImage(props.imageId)
    }

    return {
      image,
      handleClose,
      handleNextPhoto,
      handlePreviousPhoto,
      breakpoint: breakpoints.breakpoint,
      imgCross: images.cross,
      imgArrowLeft: images.arrowLeft,
      imgArrowRight: images.arrowRight
    }
  }
}
</script>

<style scoped>
.gallery-image-detail-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
}
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.image-container img {
  max-width: 80vw;
  max-height: 90vh;
}
.controls-container {
  position: fixed;
  top: 45vh;
  width: calc(100% - 4rem);
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}
.close-container {
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
}

.link {
  text-decoration: none;
  cursor: pointer;
  transition: all var(--transition-speed);
  padding: 1rem;
}
.link:hover {
  opacity: 0.5;
}
.link img,
.controls-container img {
  width: 30px;
  height: 30px;
}

@media (max-width: 960px) {
  .image-container img {
    max-width: 80vw;
    max-height: 80vh;
  }
  .controls-container {
    top: unset;
    bottom: 2rem;
    max-width: 300px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media (max-width: 600px) {
  .controls-container {
    bottom: 1rem;
  }
}
@media (max-width: 960px) and (orientation: landscape) {
  .controls-container {
    position: fixed;
    bottom: unset;
    max-width: unset;
    top: 45vh;
    width: calc(100% - 6rem);
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
</style>

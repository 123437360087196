<template>
  <article class="service-card" :class="`-${type}`">
    <div
      v-if="breakpoint.value !== 'XS' && breakpoint.value !== 'SM'"
      class="img-container"
    >
      <LazyImage
        :image="service.image"
        alt="service.title"
        :size="{ x: IMAGE_SIZE_MD }"
        isCover
      />
    </div>

    <div class="card-content-container">
      <h2>{{ service.title }}</h2>

      <div class="primary-info">
        <p v-html="service.text"></p>
        <p>Starting at € {{ service.price }}</p>

        <BaseButtonLink
          class="primary mini"
          link="https://petraproart.pixieset.com/booking/deposit"
        >
          Book now
        </BaseButtonLink>
      </div>
    </div>
  </article>
</template>

<script>
import images from '@/assets/images/images'
import BaseButtonLink from '@/components/atoms/BaseButtonLink'
import LazyImage from '@/components/atoms/LazyImage'
import breakpoints from '@/store/breakpoints'

export default {
  name: 'ServiceCard',
  props: ['service', 'type'],

  components: {
    BaseButtonLink,
    LazyImage
  },

  setup(props) {
    const IMAGE_SIZE_MD = 220

    return {
      image: images[`service_${props.service.id}`],
      IMAGE_SIZE_MD,
      breakpoint: breakpoints.breakpoint
    }
  }
}
</script>

<style scoped>
article {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.service-card.-left {
  margin-right: auto;
}
.service-card.-right {
  margin-left: auto;
}
.card-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem;
  background-color: var(--color-primary-darkest);
  text-align: left;
}

.service-card.-left .card-content-container {
  margin-right: 100px;
  padding-right: 150px;
}
.service-card.-right .card-content-container {
  margin-left: 100px;
  padding-left: 150px;
}

h2 {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.primary-info p {
  margin-bottom: 2rem;
}
.primary-info .button {
  max-width: 150px;
}
.img-container {
  position: absolute;
  bottom: 30px;
  width: 220px;
  height: 100%;

  background-position: center;
  background-size: cover;
}
.service-card.-left .img-container {
  right: 0;
}
.service-card.-right .img-container {
  left: 0;
}

@media (max-width: 600px) {
  article {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .card-content-container {
    padding: 1.5em;
  }
  .primary-info {
    text-align: center;
  }
  .service-card.-left .card-content-container {
    margin-right: 0px;
    padding-right: 1.5em;
  }
  .service-card.-right .card-content-container {
    margin-left: 0px;
    padding-left: 1.5em;
  }
}
</style>

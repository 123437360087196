<template>
  <main ref="scrollElement">
    <Gallery :agregations="agregations" :isHome="true" />
  </main>
</template>

<script>
import Gallery from '@/components/gallery/Gallery.vue'
import galleryApi from '@/services/galleryApi'
import galleryApiUtils from '@/services/utils/galleryApiUtils'
import breakpointStore from '@/store/breakpoints'
import { ref } from 'vue'

export default {
  name: 'HomeView',
  components: {
    Gallery
  },

  async mounted() {
    this.agregations = galleryApi.fetchImages('home')
    this.scrollElement.onscroll = () => {
      if (
        this.scrollElement.scrollHeight - this.scrollElement.scrollTop <
        4000
      ) {
        this.agregations = galleryApiUtils.repeatImages(this.agregations)
      }

      if (this.scrollElement.scrollTop > 25000)
        this.scrollElement.scroll(0, 25000)
    }
  },

  setup() {
    const agregations = ref(null)
    const scrollElement = ref(null)

    return {
      agregations,
      scrollElement,
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
main {
  width: 100%;
  padding: 0;
}
</style>

<template>
  <main>
    <Gallery :agregations="agregations" />
  </main>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import breakpointStore from '@/store/breakpoints'
import galleryApi from '@/services/galleryApi'
import Gallery from '@/components/gallery/Gallery.vue'

export default {
  name: 'LocationView',
  components: {
    Gallery
  },

  async mounted() {
    const possibleAggregations = galleryApi.fetchImages(this.locationId)
    return possibleAggregations
      ? (this.agregations = possibleAggregations)
      : this.$router.push({ name: 'error' })
  },

  setup() {
    const route = useRoute()
    const agregations = ref(null)

    return {
      agregations,
      breakpoint: breakpointStore.breakpoint,
      locationId: route.params.locationId
    }
  }
}
</script>

<style scoped>
main {
  width: 100%;
  padding: 0rem;
}
</style>

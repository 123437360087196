<template>
  <main>
    <MemoriesHeading />
    <div class="portfolio-container">
      <div
        v-for="portfolio in portfolios"
        class="portfolio-item"
        :key="portfolio.id"
      >
        <router-link :to="`${portfolio.path}`">
          <h3>{{ portfolio.title }}</h3>
          <LazyImage
            :image="portfolio.image"
            :alt="portfolio.title"
            :size="{
              x:
                breakpoint.value === 'XS' || breakpoint.value === 'SM'
                  ? IMAGE_SIZE_X_XS
                  : IMAGE_SIZE_X_MD,
              y:
                breakpoint.value === 'XS' || breakpoint.value === 'SM'
                  ? IMAGE_SIZE_Y_XS
                  : IMAGE_SIZE_Y_MD
            }"
          />
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { ref } from 'vue'
import breakpointStore from '@/store/breakpoints'
import portfoliosApi from '@/services/portfolioApi'
import LazyImage from '@/components/atoms/LazyImage.vue'
import MemoriesHeading from '@/components/MemoriesHeading'

export default {
  name: 'PortfolioView',
  components: {
    LazyImage,
    MemoriesHeading
  },

  async created() {
    this.portfolios = await portfoliosApi.fetchPortfolios()
  },

  setup() {
    const portfolios = ref([])

    const IMAGE_SIZE_X_XS = 260
    const IMAGE_SIZE_Y_XS = 173
    const IMAGE_SIZE_X_MD = 600
    const IMAGE_SIZE_Y_MD = 400

    return {
      portfolios,
      breakpoint: breakpointStore.breakpoint,
      IMAGE_SIZE_X_XS,
      IMAGE_SIZE_Y_XS,
      IMAGE_SIZE_X_MD,
      IMAGE_SIZE_Y_MD
    }
  }
}
</script>

<style scoped>
.portfolio-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.portfolio-item {
  position: relative;
  margin: 1rem;
}

a:hover h3 {
  color: var(--color-primary);
}
a {
  color: var(--color-primary-darker);
  transition: all var(--transition-speed);
  text-decoration: none;
}
h2 {
  margin-bottom: 3rem;
}
h3 {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 30px;
  right: 0;
  font-size: 1.3rem;
  color: var(--color-white);
  background: #0008;
  text-align: left;
  transition: all var(--transition-speed);
}

@media (max-width: 600px) {
  .portfolio-container {
    justify-content: space-around;
  }
  .portfolio-item {
    margin: 0rem;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 0.9rem;
    margin-bottom: 0rem;
  }
}
</style>

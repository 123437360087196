import { ref } from 'vue'
import { mobileCheck } from '@/utils/domUtils'
import breakpoints from '@/utils/breakpoints'

const isMobile = ref(mobileCheck())
const breakpoint = ref(breakpoints.INITIAL)

const windowWidth = ref(0)
const windowHeight = ref(0)

const initializeBreakPoint = () => {
  windowWidth.value = window.innerWidth
  windowHeight.value = window.innerHeight

  breakpoint.value = Object.values(breakpoints).find(({ min, max, value }) => {
    if (windowWidth.value >= min && windowWidth.value <= max) {
      return value
    }
  })
}

export default {
  isMobile,
  breakpoint,
  windowWidth,
  windowHeight,
  initializeBreakPoint
}

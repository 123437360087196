<template>
  <nav
    :class="{ 'is-visible': isVisible, 'is-links': $route.name === 'links' }"
  >
    <router-link v-if="!isVisible" to="/">
      <img
        :class="{ 'is-links': $route.name === 'links' }"
        :src="imgLogo"
        alt="petrapro.art photography"
      />
    </router-link>

    <h1 v-if="!isVisible && $route.name !== 'home' && $route.name !== 'links'">
      {{ $route.meta.title }}
    </h1>

    <div v-if="$route.name !== 'links'" class="link">
      <img
        v-if="!isVisible"
        :src="imgBurger"
        @click="toggleMenu"
        alt="Open menu"
      />
      <img v-else :src="imgCross" @click="toggleMenu" alt="Close menu" />
    </div>

    <div v-if="isVisible" class="mobile-menu">
      <NavLinks :isVertical="true" @linkClicked="toggleMenu" />
      <div class="socials-container">
        <Socials />
      </div>
    </div>
  </nav>
</template>

<script>
import images from '@/assets/images/images'
import NavLinks from '@/components/nav/NavLinks'
import Socials from '@/components/nav/Socials'
import { ref } from 'vue'

export default {
  name: 'NavMobile',
  components: {
    NavLinks,
    Socials
  },

  setup() {
    const isVisible = ref(false)
    const toggleMenu = () => (isVisible.value = !isVisible.value)

    return {
      toggleMenu,
      isVisible,
      imgLogo: images.logoMenu,
      imgCross: images.cross,
      imgBurger: images.burger
    }
  }
}
</script>

<style scoped>
nav {
  height: var(--nav-height);
  width: calc(100% - 1rem);
  padding: 0rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  line-height: 1;
}
nav.is-visible {
  justify-content: flex-end;
}
nav.is-links {
  justify-content: center;
}
.mobile-menu {
  position: fixed;
  top: var(--nav-height);
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--color-black);
  z-index: 10;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  width: 100px;
}
img.is-links {
  width: 210px;
}

.link {
  text-decoration: none;
  cursor: pointer;
  transition: all var(--transition-speed);
}
.link:hover {
  color: var(--color-primary);
}
.link img {
  width: 100px;
  height: 30px;
}
</style>

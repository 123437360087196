<template>
  <div>
    <NavLinks />
  </div>
</template>

<script>
import NavLinks from '@/components/nav/NavLinks'

export default {
  name: 'Nav',
  props: ['isHome'],
  components: {
    NavLinks
  },

  setup() {
    return {}
  }
}
</script>

<style scoped>
div {
  position: fixed;
  height: var(--nav-height);
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

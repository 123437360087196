<template v-if="isLoading">
  <main>
    <LoadingIcon
      :size="{
        x: breakpoint.value === 'XS' || breakpoint.value === 'SM' ? 250 : 300
      }"
    />
  </main>
</template>

<script>
import LoadingIcon from '@/components/atoms/LoadingIcon'
import breakpointStore from '@/store/breakpoints'
import { useRoute } from 'vue-router'

export default {
  name: 'InstagramHackView',
  components: {
    LoadingIcon
  },

  setup() {
    const route = useRoute()

    setTimeout(() => {
      window.location = `${window.origin}/${route.meta.instagramHackRoute}`
    }, 1000)

    return {
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  display: flex;
  justify-content: center;

  background: var(--color-black);
}
</style>

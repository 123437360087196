<template>
  <div class="text-container">
    <LazyImage
      :image="aboutImage.image100"
      alt="Petra Prochazkova"
      :size="{
        x:
          breakpoint.value === 'XS' || breakpoint.value === 'SM'
            ? IMAGE_SIZE_X_XS
            : IMAGE_SIZE_X_MD,
        y:
          breakpoint.value === 'XS' || breakpoint.value === 'SM'
            ? IMAGE_SIZE_Y_XS
            : IMAGE_SIZE_Y_MD
      }"
    />
    <div class="text-content">
      <p>
        This page was created to have a successful photoshoot together. The more
        you enjoy it, the more amazing moments I can capture for you.
      </p>
      <p>
        On this page, you will find location suggestions, ideas for what to wear
        and how to prepare yourself to look and feel the best. Read till the end
        or contact me straight away!
      </p>
      <BaseButton
        class="primary mini"
        :onClick="() => $router.push({ name: 'contact' })"
      >
        Contact me
      </BaseButton>
    </div>
  </div>
</template>

<script>
import breakpointStore from '@/store/breakpoints'
import BaseButton from '@/components/atoms/BaseButton'
import coupleImages from '@/assets/images/portfolio/couples/couples'
import LazyImage from '@/components/atoms/LazyImage'

const IMAGE_SIZE_X_XS = 260
const IMAGE_SIZE_Y_XS = 173
const IMAGE_SIZE_X_MD = 400
const IMAGE_SIZE_Y_MD = 320

export default {
  name: 'BookViewIntro',
  components: {
    BaseButton,
    LazyImage
  },

  setup() {
    return {
      breakpoint: breakpointStore.breakpoint,
      aboutImage: coupleImages.find(image => image.id === 'cpl-040'),
      IMAGE_SIZE_X_XS,
      IMAGE_SIZE_Y_XS,
      IMAGE_SIZE_X_MD,
      IMAGE_SIZE_Y_MD
    }
  }
}
</script>

<style scoped>
h2 {
  margin-bottom: 2rem;
}

.text-container {
  display: flex;
  align-items: center;
}
.text-content {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-content p:last-of-type {
  margin-bottom: 2rem;
}
p {
  text-align: justify;
}

@media (max-width: 960px) {
  h2 {
    margin-bottom: 0;
  }
  .text-container {
    flex-direction: column;
  }
  .text-content {
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  h2 {
    margin-bottom: 1rem;
  }
  .text-content {
    margin-top: 2rem;
  }
}
</style>

<template>
  <transition name="fade" mode="out-in">
    <div v-show="!agregation || isLoading" class="gallery-loading-container">
      <LoadingIcon
        :size="{
          x: breakpoint.value === 'XS' || breakpoint.value === 'SM' ? 250 : 300
        }"
      />
    </div>
  </transition>
  <transition name="fade">
    <div
      v-show="agregation && !isLoading && !isPostponedLoading"
      class="gallery-container"
      :class="{ 'is-home': isHome }"
    >
      <div
        v-for="(images, index) in agregation.images"
        class="column"
        :key="index"
      >
        <GalleryImage
          v-for="image in isLoading ? images.slice(0, 5) : images"
          :image="image"
          :key="image.id"
          @onLoad="handleOnLoad"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, computed, watch } from 'vue'
import GalleryImage from '@/components/gallery/GalleryImage'
import LoadingIcon from '@/components/atoms/LoadingIcon'
import breakpointStore from '@/store/breakpoints'

export default {
  name: 'Gallery',
  props: ['agregations', 'isHome'],
  components: {
    GalleryImage,
    LoadingIcon
  },

  setup(props) {
    const loadedImages = ref([])
    const isPostponedLoading = ref(true)

    const agregation = computed(() => {
      const breakpoint = breakpointStore.breakpoint.value.value
      if (breakpoint === 'XS' || breakpoint === 'SM') {
        return props.agregations ? props.agregations[2] : {}
      }
      if (breakpoint === 'MD') {
        return props.agregations ? props.agregations[3] : {}
      }
      if (breakpoint === 'LG') {
        return props.agregations ? props.agregations[4] : {}
      }

      return props.agregations
        ? props.isHome
          ? props.agregations[5]
          : props.agregations[4]
        : {}
    })
    const isLoading = computed(() => {
      if (!agregation.value.interestingImagesIds) return true
      return !agregation.value.interestingImagesIds.every(image => {
        return loadedImages.value.includes(image)
      })
    })

    watch(isLoading, () => {
      setTimeout(() => {
        isPostponedLoading.value = isLoading.value
      }, 500)
    })

    const handleOnLoad = imageId => {
      loadedImages.value.push(imageId)
    }

    return {
      isPostponedLoading,
      agregation,
      loadedImages,
      isLoading,
      handleOnLoad,
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
main {
  width: 100%;
}
.gallery-container,
.gallery-loading-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 700px;
}
.gallery-loading-container {
  padding-top: 80px;
}
.column {
  flex: 25%;
  max-width: 25%;
}
.gallery-container.is-home .column {
  flex: 20%;
  max-width: 20%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.5s;
}
.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 1;
}

@media (max-width: 1280px) {
  .gallery-container.is-home .column,
  .column {
    flex: 25%;
    max-width: 25%;
  }
}
@media (max-width: 960px) {
  .gallery-container.is-home .column,
  .column {
    flex: 33%;
    max-width: 33%;
  }
  .gallery-container.is-home .column:nth-child(2),
  .column:nth-child(2) {
    flex: 34%;
    max-width: 34%;
  }
}
@media (max-width: 600px) {
  .gallery-loading-container {
    padding-top: 30px;
  }
  .gallery-container.is-home .column,
  .column {
    flex: 50%;
    max-width: 50%;
  }
  .gallery-container.is-home .column:nth-child(2),
  .column:nth-child(2) {
    flex: 50%;
    max-width: 50%;
  }
}
</style>

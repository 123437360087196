<template>
  <main>
    <div class="content-container">
      <BookForm />
    </div>
  </main>
</template>

<script>
import breakpointStore from '@/store/breakpoints'
import BookForm from '@/components/forms/BookForm.vue'

export default {
  name: 'ContactView',
  components: {
    BookForm
  },

  setup() {
    return {
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
.content-container {
  text-align: center;
  max-width: 700px;
}

@media (max-width: 600px) {
  main {
    width: calc(100% - 3rem);
  }
}
</style>

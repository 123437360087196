<template>
  <div
    class="transitioned-paragraph"
    ref="container"
    :style="{
      height: isMeasuringDone
        ? isOpen
          ? `${heightWhenOpen}px`
          : '0px'
        : 'auto'
    }"
  >
    <p v-html="text"></p>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue'

export default {
  name: 'TransitionedParagraph',
  props: {
    text: { type: String, required: true },
    isOpen: { type: Boolean, required: true }
  },

  setup() {
    const container = ref(null)
    const heightWhenOpen = ref(0)
    const isMeasuringDone = ref(false)

    onMounted(() => {
      nextTick(() => {
        heightWhenOpen.value = container.value.getBoundingClientRect().height
        isMeasuringDone.value = true
      })
    })

    return {
      container,
      isMeasuringDone,
      heightWhenOpen
    }
  }
}
</script>

<style scoped>
.transitioned-paragraph {
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}
.transitioned-paragraph p {
  padding: 10px 0px;
  text-align: justify;
}
a {
  color: var(--color-primary);
  text-decoration: none;
}
</style>

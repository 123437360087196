<template>
  <main>
    <h1>
      Sorry, but this page does not exist.
    </h1>
    <p>
      I am not sure what are you looking for, but you should better head back to
      <router-link to="/">
        home.
      </router-link>
    </p>
  </main>
</template>

<script>
import breakpointStore from '@/store/breakpoints'

export default {
  name: 'ErrorView',
  components: {},

  setup() {
    return {
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
p {
  text-align: center;
}
</style>

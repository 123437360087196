<template>
  <div class="tags">
    <router-link
      v-for="(tag, index) in tags"
      :key="tag"
      :to="`/stories?tag=${tag}`"
      class="in-text"
    >
      <span>{{ tag }}</span>
      <span v-if="index !== tags.length - 1">,</span>
    </router-link>
    <span>•</span>
    <span class="date">{{ date }}</span>
  </div>
</template>

<script>
export default {
  name: 'StoryTags',
  props: {
    date: { type: String, required: true },
    tags: { type: Array, required: true }
  },

  setup() {
    return {}
  }
}
</script>

<style scoped>
.tags {
  opacity: 0.7;
  margin-bottom: 15px;
}
.in-text {
  margin-right: 7px;
}
.date {
  margin-left: 7px;
}
</style>

<template>
  <h2>
    Memories can fade.
    <br v-if="breakpoint.value === 'XS' || breakpoint.value === 'SM'" />
    Photos are forever.
  </h2>
</template>

<script>
import breakpointStore from '@/store/breakpoints'

export default {
  name: 'MemoriesHeading',

  setup() {
    return {
      breakpoint: breakpointStore.breakpoint
    }
  }
}
</script>

<style scoped>
h2 {
  text-align: center;
  margin-bottom: 2rem !important;
}
</style>

const fetchFaq = async () => {
  return [
    {
      id: '0',
      title: 'How can I book a photoshoot?',
      paragraph:
        'Head over to my <a href="/contact-me" class="in-text">contact page</a> and send me a message with as many details as you can think of! We’ll figure out a time to meet in person in Amsterdam or another place in the Netherlands for a photoshoot.'
    },
    {
      id: '1',
      title: 'How will I get my photos?',
      paragraph:
        'After our shooting, I will take some time to cull the photos and in 2 days I will deliver the gallery on Pixieset with 150 pre-selected photos. After you select the best ones, I will send you the all edited photos in high resolution via <a href="https://wetransfer.com"/ class="in-text" noreferrer noopener rel="noreferrer">wetransfer.com</a> in 2 weeks.'
    },
    {
      id: '2',
      title: 'How long does the photoshoot take?',
      paragraph:
        'For my standard portrait and couple sessions about 1 hour per outfit and location. 2 hours for two outfits and 1-2 locations nearby.'
    },

    {
      id: '3',
      title: 'Do you instruct for posing? I’m kind of awkward/unphotogenic.',
      paragraph:
        'Most of us aren’t models, so it’s no surprise that the vast majority of people tell me when we first meet that they’re awkward on camera or they never look good in photos. These are the same people that appear in my <a href="/portraits" class="in-text">favorite images</a>. Sometimes giving a great direction means letting you know where to go, getting you to do something silly, and capturing a REAL moment of laughter. I will help you with that.'
    },
    {
      id: '4',
      title: 'What happens in case of bad weather or if it rains?',
      paragraph:
        'As a photographer in Amsterdam, I have to deal with bad weather every day. My equipment is weather sealed and my rain jacket is ready. I’m happy to help you figure out the outfit and additional challenges of unexpected weather on our photoshoot day. But when the heavy rain will not stop all day long, we can find a more suitable day for our photoshoot.'
    },
    {
      id: '5',
      title: 'Why do I have to pay a deposit?',
      paragraph:
        'The reason for the deposit is to block your date and time. If you cancel, I will most likely be able to book someone else during that period. Your date and time are not reserved until the deposit is paid.'
    },
    {
      id: '6',
      title: 'When do I pay the remainder? Can I pay all upfront?',
      paragraph:
        'The remainder of your session and travel fee is due immediately following your shoot before we both depart. No images will be delivered until the final payment is made. It is possible to pay the full amount in advance.'
    },
    {
      id: '7',
      title: 'What are location travel fees?',
      paragraph:
        'This applies only to photoshoots that are not situated in Amsterdam and near surroundings. I charge a travel fee so I can get to and from our photoshoot with my camera gear. It is usually the price of a ticket for a train or bus.'
    },
    {
      id: '8',
      title: 'Can I bring a friend?',
      paragraph:
        'Yes! Take a friend, family member, partner, or a pet with you. Anyone who makes you happy and cheers you up is more than welcome.'
    },
    {
      id: '9',
      title: 'How long do you store our images?',
      paragraph:
        "I clean the hard drive on which I store photos about every 3 - 4 months. So I can't guarantee a backup of your photos. Please make sure you have downloaded all the photos in time and that you have made your backup of all the photos. I do not store the raw files for more than 4 months."
    },
    {
      id: '10',
      title: 'Can we pick what images go on your website?',
      paragraph:
        'No. All edited photos I send to you can appear on my website and social media.'
    },
    {
      id: '11',
      title:
        'What if we don’t want the images used on your social media, website, or anywhere online?',
      paragraph:
        'Please make it clear to me in your initial contact email if this is the case and let me know the reason. I market my photography services by sharing photos on the internet and I rely on sharing my photography to bring in customers (and you found me!). Therefore, I do have a fee if this is something really important to you, please inquire.'
    },
    {
      id: '12',
      title: 'What if we are not happy with the final images?',
      paragraph:
        'Never happened to me before, but let me know why and what you don’t like about the photos. We will find a way to be all happy with the result.'
    },
    {
      id: '13',
      title: 'Do I get to select the images?',
      paragraph:
        'It’s a part of my job to choose images that are the most flattering. The photographs I do not deliver are either repeat, where you are talking or just blinking or shots that are out of focus. I always make a pre-selection and send about 150 photos in the preview, from which you can choose the ones you will love the most.'
    },
    {
      id: '14',
      title: 'Can I have the unedited versions or raw files?',
      paragraph:
        'No. Many times when we look for a photographer, we look for the style that draws our attention, so dedication to treatment is essential for my work. All photos are treated with the style of the photograph, so I try to develop a customized edition for each client without losing my style.'
    },
    {
      id: '15',
      title: 'Will you retouch how I look?',
      paragraph:
        "No, I do not change the appearance of the customer. If it's something you're interested in, I'll be happy to do it for you. I charge an additional fee for this type of work."
    },
    {
      id: '16',
      title: 'What is the difference between EDIT and RETOUCH?',
      paragraph:
        '<strong>Editing</strong> - The first step in my editing process is to cull the photos. I go through all of the pictures that were taken and determine which pictures will be delivered. For example, someone stepped in front of my camera, everyone has their eyes closed, or the pose is unflattering etc. I edit every photo that I send to a client. I correct pictures for lighting, exposure, contrast,  white balance, colour adjustments, crop and/or straighten the image if necessary, as well as adding my own ‘signature’ touches to the images.<br><br><strong>Basic retouch</strong> - If you desire, there is what I can do for you. I can remove imperfections, blemishes, wrinkles, brightening teeth or circles under the eyes, acne and soften the skin with a natural look.  But it doesn’t have to be only about your skin, I can also change the sky or remove unwanted objects from the background (only small objects that are not across the entire background).<br><br><strong>Extensive retouch</strong> - Removing a person from an image, compositing several images together, or completely changing the background of an image. It is the manipulation of the whole image or even the removal of big elements out of the picture.'
    }
  ]
}

const fetchGoogleFaq = async () => {
  return [
    {
      id: '1-0',
      title: 'What not to wear?',
      paragraph:
        'Never wear something that you have not tried before, that does not suit you and is not comfortable. The whole photoshoot would be one big unpleasant experience for you. There is nothing worse than wearing shoes that rub you or a sweater that bites. You will be unhappy with the whole photoshoot and it will be projected into photos.'
    },
    {
      id: '1-1',
      title:
        'What should I wear for professional photos on LinkedIn, CV or for work?',
      paragraph:
        'I suggest more formal wear, but definitely, one that will make you comfortable. Try to think about usual things that you wear to work - if you don’t wear a suit at work, don’t wear it on our photoshoot. It should be clean and ironed clothes without logos and inscriptions, preferably in a single colour or with a decent pattern. Let me know in the form, what type of photoshoot and for what purpose it will be. Together we will fine-tune the entire outfit that will be the most matching for your photoshoot.'
    },
    {
      id: '1-2',
      title: 'What should I wear for a lovestory or a family photoshoot',
      paragraph:
        "The key for stunning photos in this case is to tune clothes together. You don't have to be all dressed the same, but I definitely recommend some details that you will have in common. For example, a strong colour. One of you can wear a T-shirt in the same colour, that other ones trousers. Another cute example is that you can have the same shoes. The possibilities are endless, but you have to complement each other. If one has formal clothes and the other one comes in sportswear, the photos will not be as good as they could be."
    }
  ]
}

export default {
  fetchFaq,
  fetchGoogleFaq
}

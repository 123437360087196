<template>
  <article>
    <router-link :to="`/stories/${id}`">
      <img :src="image" :alt="title" />
    </router-link>

    <StoryTags :tags="tags" :date="date" />

    <router-link class="in-text" :to="`/stories/${id}`">
      <h2 v-html="title" />
    </router-link>

    <p>{{ summary }}</p>

    <router-link class="in-text" :to="`/stories/${id}`">
      <span>Read more</span>
    </router-link>
  </article>
</template>

<script>
import images from '@/assets/images/images'
import breakpointStore from '@/store/breakpoints'
import { ref } from 'vue'
import StoryTags from './StoryTags.vue'

export default {
  name: 'StoryCard',
  props: {
    date: { type: String, required: true },
    id: { type: String, required: true },
    summary: { type: String, required: true },
    tags: { type: Array, required: true },
    title: { type: String, required: true }
  },
  components: { StoryTags },

  setup(props) {
    const image = ref(
      breakpointStore.breakpoint.value.value === 'XS' ||
        breakpointStore.breakpoint.value.value === 'SM'
        ? images[`stories_${props.id}-small`]
        : images[`stories_${props.id}`]
    )

    return {
      image
    }
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
  margin-bottom: 15px;
}

h2 {
  margin-bottom: 0px;
}

p {
  line-height: 1.7;
}
</style>

import { reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import servicesApi from '@/services/servicesApi'
import emailApi from '@/services/emailApi'

const INITIAL_STATE = {
  name: '',
  email: '',
  message: '',

  service: 'Nothing yet, just message',
  services: [
    'Nothing yet, just message',
    ...Object.values(servicesApi.servicesMap)
  ],
  date: '',
  location: '',

  status: 'ready'
}
const formState = reactive({ ...INITIAL_STATE })

const resetFormState = () => {
  formState.value = { ...INITIAL_STATE }
}
const setService = serviceId => {
  formState.service = servicesApi.servicesMap[serviceId]
}

const rules = {
  name: {
    required: helpers.withMessage(
      'I would love to know your name before.',
      required
    )
  },
  email: {
    required: helpers.withMessage(
      'I need to know your email, so I can respond.',
      required
    ),
    email: helpers.withMessage('This does not look like an email.', email)
  },
  message: {
    required: helpers.withMessage(
      'What would you like to know? Please fill the message.',
      required
    )
  }
}

const vuelidate = useVuelidate(rules, formState)
const handleSubmit = async event => {
  event.preventDefault()

  vuelidate.value.$touch()

  if (vuelidate.value.$error) return

  formState.status = 'loading'
  const response = await emailApi.sendEmail({
    user_email: formState.email,
    user_name: formState.name,
    user_message: formState.message,
    user_service: formState.service,
    user_date: formState.date,
    user_location: formState.location
  })

  if (response.status === 200) {
    formState.status = 'success'
    resetFormState()
    return
  }
  formState.status = 'error'
}

export default {
  formState,
  rules,
  vuelidate,
  handleSubmit,
  resetFormState,
  setService
}

<template>
  <template
    v-if="
      $route.name === 'home' ||
        $route.name === 'portfolio' ||
        $route.name === 'portraits' ||
        $route.name === 'couples' ||
        $route.name === 'family' ||
        $route.name === 'portfolio' ||
        $route.name === 'miniSessions' ||
        $route.name === 'services' ||
        $route.name === 'about'
    "
  >
    <BaseButton
      class="primary"
      @click="() => $router.push({ name: 'contact' })"
    >
      Book now
    </BaseButton>
  </template>

  <template v-if="$route.name === 'book'">
    <div>
      <BaseButton
        class="primary"
        @click="() => $router.push({ name: 'contact' })"
      >
        Contact me
      </BaseButton>
    </div>
  </template>

  <template
    v-if="$route.name === 'contact' || $route.name === 'contactInstagram'"
  >
    <div>
      <BaseButton class="primary" @click="handleSubmit">
        Send
      </BaseButton>
    </div>
  </template>
</template>

<script>
import breakpoints from '@/store/breakpoints'
import bookStore from '@/store/book'
import BaseButton from '@/components/atoms/BaseButton.vue'

export default {
  name: 'FooterAction',
  components: {
    BaseButton
  },

  setup() {
    return {
      breakpoint: breakpoints.breakpoint,
      handleSubmit: bookStore.handleSubmit
    }
  }
}
</script>

import images from '@/assets/images/images'

const fetchPortfolios = async () => {
  return [
    {
      id: 'couple',
      title: 'Couples',
      path: 'couples',
      image: images.portfolio_couples_001
    },
    {
      id: 'portraits',
      title: 'Portraits',
      path: 'portraits',
      image: images.portfolio_portraits_001
    }
  ]
}

export default {
  fetchPortfolios
}

const fetchTestimonials = () => {
  return [
    {
      name: 'Danielle + Gintaras',
      message:
        'Petra was very nice to work with. The communication before and during the shoot was pleasant. She was professional and polite. We look forward to seeing the results and would work with Petra again!'
    },
    {
      name: 'Ridiona',
      message:
        'Petra is an amazing person and photographer. Her communications skills are excellent and she has the ability to put you at ease while shooting. She captured fantastic natural moments, portraits expressing emotions and authenticity. Sharp eye! We had a great fun time with Petra and looking forward to working with her again. I would highly recommend her! '
    },
    {
      name: 'Courtney',
      message:
        'Working with Petra is so much fun! She has amazing ideas and can create beautiful magic with just her camera alone. She helps encourage, so if you’re shy, no problem! Petra is so kind and sweet and is always open to ideas, will listen, will make you feel confident and much more! She has shot me and with my boyfriend in two separate sessions and the photos came out breathtakingly beautiful :) 5 golden stars to her'
    },
    {
      name: 'Inge',
      message:
        'I am so happy with the pictures 🙏 thank you so much! I really would like to work with you again 😊'
    },
    {
      name: 'Firdaus',
      message:
        'I really like how you edited the pictures, so honestly there’s no negative feedback from me. I enjoyed working with you!'
    },
    {
      name: 'Jessie',
      message: 'I love them!! Thank you!!!:)'
    },
    {
      name: 'Veerle + Nilson',
      message:
        'Petra is very cool, kind and collected. The communication before the shoot was friendly and thorough. Shooting with her was both relaxing and exhilarating. She is fun and easy to work with.  I would definitely say yes at the chance of collaborating again and I HIGHLY recommend her'
    }
  ]
}

export default {
  fetchTestimonials
}

<template>
  <main>
    <div class="content-container">
      <div class="grid">
        <StoryCard
          v-for="story in !$route.query.tag
            ? stories
            : stories.filter(story => story.tags.includes($route.query.tag))"
          :date="story.date"
          :key="story.id"
          :id="story.id"
          :images="story.images"
          :summary="story.summary"
          :tags="story.tags"
          :title="story.title"
        />
      </div>
    </div>
  </main>
</template>

<script>
import StoryCard from '@/components/stories/StoryCard.vue'
import stories from '@/services/blogApi'
import breakpointStore from '@/store/breakpoints'

export default {
  name: 'StoriesView',
  components: {
    StoryCard
  },

  setup() {
    return {
      breakpoint: breakpointStore.breakpoint,
      stories
    }
  }
}
</script>

<style scoped>
main {
  padding-top: 1rem;
}
.content-container {
  max-width: 960px;
  padding-bottom: 2rem;
}
.grid {
  display: grid;
  grid-template-columns: calc(50% - 25px) calc(50% - 25px);
  gap: 50px;
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>

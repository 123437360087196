<template>
  <div class="loading-container">
    <img
      :src="loadingImg"
      alt="Loading..."
      :style="{
        width: size ? `${size.x}px` : '100px',
        height: size ? `${size.x}px` : '100px'
      }"
    />
  </div>
</template>

<script>
import images from '@/assets/images/images'

export default {
  name: 'LoadingIcon',
  props: ['size'],

  setup() {
    return {
      loadingImg: images.loading
    }
  }
}
</script>

<style scoped>
.loading-container {
  margin: 2rem auto;
}
.loading-container img {
  opacity: 0.4;
  animation: pulse 1.3s infinite;
}
</style>
